import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";

const ReviewShare = ({ errors, reviewData, handleBackStep, handleReview }) => {
    return (
        <Fragment>

            <div className="col-md-3">
                {/* Tab navs */}
                <div className="nav flex-column nav-tabs text-center New-auditTabs" id="v-tabs-tab">
                    <span className="nav-link">Create new audit</span>
                    <span className="nav-link">Keynote speech</span>
                    <span className="nav-link">Use forms/templates</span>
                    <span className="nav-link">Generate link</span>
                    <span className="nav-link active">Review &amp; Save</span>
                </div>
                {/* Tab navs */}
            </div>
            <div className="col-md-9">
                <div className="Audit-form-div">
                    {/* Tab content */}
                    <div className="tab-content">
                        {/* Start Review Audit Details*/}
                        <div className="tab-pane active" id="v-tabs-share" role="tabpanel">
                            <div className="audit-form">
                                <div className="audit-header">
                                    <h5 className="audit-title">Review Audit Details</h5>
                                </div>
                                <div className="audit-form-content">
                                    <form onSubmit={handleReview}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>Company Logo</label>
                                                <p className="c-logo">
                                                    {
                                                        reviewData.audit_logo_path ?
                                                            (<img src={reviewData.audit_logo_path} alt="Logo" className="img-fluid" />) : reviewData.audit_logo && reviewData.audit_logo !== "null" ? (!reviewData.audit_logo.includes('public') ? <img src={reviewData.audit_logo_path} alt="Logo" className="img-fluid" /> : <img src={`https://api.teamactionmanagement.com/storage${reviewData.audit_logo.replace("public", "")}`} alt="Logo" className="img-fluid" />) : "Please Upload an image"
                                                    }
                                                </p>
                                                {errors.logo && <p style={{ color: "red" }}>{errors.logo}</p>}
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Audit Name</label>
                                                    <input type="text" className="form-control" readOnly value={reviewData.audit_name} />
                                                    {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Keynote Speech</label>
                                                    <p>{reviewData.audit_speech_detials}</p>
                                                    {errors.speech && <p style={{ color: "red" }}>{errors.speech}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Form</label>
                                                    <input type="text" className="form-control" readOnly value={reviewData.template_name} />
                                                    {errors.template && <p style={{ color: "red" }}>{errors.template}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Audit Link <a href={reviewData.id ? reviewData.template_link : `/viewTemplate`} target="_blank" rel="noreferrer">(opens in new window)</a></label>
                                                    <input type="text" className="form-control" readOnly value={reviewData.template_link} />
                                                    {errors.link && <p style={{ color: "red" }}>{errors.link}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        {/* start footer buttons*/}
                                        <div className="col-md-12 px-0">
                                            <div className="text-right d-block audit-form-footer">
                                                <button type="button" className="mr-2 text-Bck btn" onClick={handleBackStep}>Back</button>
                                                <button type="submit" className="mr-2 text-Next btn" name="SaveAsDraft">Save as Draft</button>
                                                <button type="submit" className="mr-2 text-Next btn" name="Save">Save</button>
                                                {/* <button type="button" className="text-Share btn">Share Audit</button> */}
                                            </div>
                                        </div>
                                        {/* End footer buttons*/}
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* End Review Audit Details*/}
                    </div>
                    {/* Tab content */}
                </div>
            </div>
            <ToastContainer />
        </Fragment>
    )
}
export default ReviewShare;