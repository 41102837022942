import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import Header from './Header';
import Navbar from './Navbar';
import AuthUser from './Auth/AuthUser';
function Dashboard() {
  const navigate = useNavigate();
  const { user_id, http } = AuthUser();
  const [auditList, setAuditList] = useState("");
  const [count, setCount] = useState("");

  const handleEdit = (e, id) => {
    e.preventDefault();
    navigate(`/create-audit/${id}`);
  }
  const handleSideBar = () => {
    document.body.classList.remove('offcanvas-active')
  }
  useEffect(() => {
    if (!auditList) {
      http.get(`/userallaudit/${user_id}/getAll`)
        .then(function (response) {
          setAuditList(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    if (!count) {
      http.get(`/userallaudit/${user_id}/getCount`)
        .then(function (response) {
          setCount(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [auditList, count]);
  return (
    <Fragment>
      <div id="wrapper">
        <Header />
        <div className="middlepart">
          <Navbar />
          <div id="main-content" onClick={handleSideBar}>
            <div className="container-fluid mb-5 NewAudit">
              <div className="Audicreate-div">
                <h2>Start New Audit</h2>
                <div className="row clearfix">
                  <div className="col-lg-2 col-md-6">
                    <div className="card top_counter Box_1">
                      <div className="body">
                        <div className="icon text-info">
                          <Link to="/create-audit">
                            <img src={process.env.PUBLIC_URL + "/assets/images/create-your-audit.png"} alt="create-your-audit-icon"></img>
                          </Link>
                        </div>
                        <div className="content">
                          <Link to="/create-audit">
                            <p>Create your Audit</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 Box_2main">
                    <div className="card top_counter Box_2">
                      <div className="body">
                        <ul className="si-metadata">
                          <li>
                            <div className="si-metadata-genius">
                              <span className="si-token-large">{count.Active ? count.Active < 10 ? `0${count.Active}` : count.Active : "--"}</span> Active Audits
                            </div>
                          </li>
                          <li>
                            <div className="si-metadata-genius">
                              <span className="si-token-large">{count.Draft ? count.Draft < 10 ? `0${count.Draft}` : count.Draft : "--"}</span> Draft Audits
                            </div>
                          </li>
                          <li>
                            <div className="si-metadata-genius">
                              <span className="si-token-large">{count.Future ? count.Future < 10 ? `0${count.Future}` : count.Future : "--"}</span> Future Audits
                            </div>
                          </li>
                          <li>
                            <div className="si-metadata-genius">
                              <span className="si-token-large">{count.Expired ? count.Expired < 10 ? `0${count.Expired}` : count.Expired : "--"}</span> Closed Audits
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="card top_counter Box_3">
                      <div className="body">
                        <ul className="si-metadata">
                          <li className='d-none'>
                            <div className="si-metadata-genius">
                              <span className="si-token-large">---</span> Average Completion Time
                            </div>
                          </li>

                          <li>
                            <div className="si-metadata-genius">
                              <span className="si-token-large">{count.total < 10 && count.total ? 0 : ""}{count.total || "--"}</span> Total Audits Created
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mb-4">
              <div className="Audicreate-div">
                <h2>Recent Audits</h2>
                <div className="row clearfix">
                  <div className="col-lg-12 ">
                    <div className="audits-list">
                      <ul className="audits-items-list">
                        {
                          (auditList.length > 0 && auditList !== "") ? (auditList.map((item) => {
                            return (
                              <li className="status-holder" key={item.id}>
                                <div className="audits-item ">
                                  <div className="si-main-heading">
                                    <div className="si-audits-title">
                                      <a href={'/auditDetails/' + item.id}>{item.audit_name}</a>
                                    </div>
                                    <div className="si-subtitle">
                                      <ul className="si-subtitle-items-list">
                                        <li>Start Date: {item.created_at}</li>
                                        <li>|</li>
                                        <li>End Date: {item.expired_at}</li>
                                      </ul>
                                      <div className="si-sharing"></div>
                                    </div>
                                  </div>
                                  <ul className="si-metadata">
                                    <li>
                                      <div className="si-metadata-genius"><span className={`si-status ${(item.status === "Active" || item.status === "active") ? "audits-status-active" : ""} 
                                      ${(item.status === "future" || item.status === "Future") ? "audits-status-future" : ""}
                                      ${(item.status === "expired" || item.status === "Expired") ? "audits-status-closed" : ""}
                                      ${(item.status === "draft" || item.status === "Draft") ? "audits-status-draft" : ""}`}>{item.status}</span>

                                        <p>Overall Audit
                                          Status</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="si-metadata-genius">
                                        <span className="si-token-large">{item.link_count}</span> Collectors
                                      </div>
                                    </li>
                                    <li className='d-none'>
                                      <div className="si-metadata-genius">
                                        <span className="si-token-large">—</span> Estimated time <br></br> to complete
                                      </div>
                                    </li>

                                    <li className='d-none'>
                                      <div className="si-metadata-genius">
                                        <span className="si-token-large"><img src={process.env.PUBLIC_URL + "/assets/images/data-analysis-icon.png"} alt="Analysis Icon" className="img-fluid "></img></span> Analyze
                                      </div>
                                    </li>
                                    <li>
                                      <div className="si-metadata-genius text-center w-auto">
                                        <button type='button' className="btn btn-primary mb-2 m-2" onClick={(e) => handleEdit(e, item.id)}>Edit</button>
                                        <Link to={'/auditDetails/' + item.id} className="btn btn-em">View report</Link>
                                      </div>
                                    </li>
                                  </ul>

                                </div>
                              </li>
                            )
                          })) : <li><div className="row clearfix">
                            <div className="col-lg-12 ">
                              <div className="audits-list">
                                <div className="Nodata-found">
                                  <h2>No Response Collected</h2>
                                </div>
                              </div>
                            </div>
                          </div></li>
                        }

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Dashboard;
