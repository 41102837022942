
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export default function SideBar() {

    return (
        <Fragment>
          
                <div className="bg" style={{ backgroundImage: `url("https://audit.teamactionmanagement.com/assets/images/login-bg.png")`, width: '40%' }}>
                    <div className="text mt-4 mt-md-0 LogInFrom img">
                        <Link to="/" tabIndex={1}><img  src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="Logo"></img></Link>

                    </div>
                </div>
           

        </Fragment>
    )
}
