import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import AuthUser from './AuthUser';
function Resetpin() {
    const navigate = useNavigate();
    const { http } = AuthUser();
    const [email, setEmail] = useState();
    const [pin, setPin] = useState();
    function ForgetpasswordPin() {
        http.post('/verify/pin', { email: email, token: pin }).then((res) => {
            navigate('/resetpassword', { replace: false });
        });
    }
    return (
        <Fragment>
            <div className="d-lg-flex half">
                <div className="bg" style={{ backgroundImage: `url("https://audit.teamactionmanagement.com/assets/images/login-bg.png")`, width: '40%' }}>
                    <div className="text mt-4 mt-md-0 LogInFrom img">
                        <a href="#"><img src={process.env.PUBLIC_URL + '/assets/images/logo.png'}></img></a>

                    </div>
                </div>
                <div className="contents">
                    <div className="container" data-aos="fade-right">

                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-11 LogInFrom" >
                                <div className="row mt-4 mb-4">
                                    <div className="col-md-6 col-sm-12">
                                        <p> <Link to="/"> <i className="fa fa-arrow-left"></i>Back to the homepage</Link></p>
                                    </div>
                                    <div className="col-md-6 col-sm-12 ">
                                        <div className="col-md-12 top text-center">
                                            <span className="helper-text">Don't have an account? <a href="./register.html">Register from here <i className="fa fa-arrow-right" ></i></a></span>
                                        </div>
                                    </div>
                                </div>
                                <h1>Forgot Password Pin</h1>
                                <p className="mb-4">Validated pin sent you email please check.</p>
                                <form action="#" method="post" className="FormLogin">
                                    <div className="row">
                                        <div className="col-md-12 floating-label">
                                            <label>Email Address</label>
                                            <input type="email" name="email" onChange={e => setEmail(e.target.value)}></input>
                                        </div>
                                        <div className="col-md-12 floating-label">
                                            <label>Enter your 6-digit pin</label>
                                            <input type="text" name="pin" onChange={e => setPin(e.target.value)}></input>
                                        </div>

                                        <div className="col-md-12 mb-3 LogButtons text-center">
                                            <button className="btn login" type="button" onClick={ForgetpasswordPin} >Submit</button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </Fragment>
    )
}

export default Resetpin;