
const Validation = (values) => {
    let errors = {}

    if (!values.email) {
        errors.email = "Email is required!";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    if (!values.password) {
        errors.password = "Password is required!";
    } else if (values.password.length < 6) {
        errors.password = "Password must be 6 character!";
    }
    // if (!values.c_password) {
    //     errors.c_password = "Confirm Password is required!";
    // } else if (values.c_password.length < 6) {
    //     errors.c_password = "Confirm Password must be 6 character!";
    // }

    // if(values.c_password !== values.password){
    //     errors.c_password = "Password not match";
    // }
    return errors;
}
export default Validation;