import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, set } from 'date-fns'

/* Import form validations */
import { Validation, ValidationKey, ValidationLink, ValidationTemplate, ValidationShare } from "./validation";

/* Import Tab components */
import NewAudit from './NewAudit';
import KeyNoteSpeech from './KeyNoteSpeech';
import UseTemplates from './UseTemplates';
import GenerateLink from './GenerateLink';
import ReviewShare from './ReviewShare';


function MainStep({ id, user_id, http }) {
    const navigate = useNavigate();
    const assetsUrl = "https://api.teamactionmanagement.com/storage/"
    const [step, setStep] = useState(1);
    const [createDate, setCreateDate] = useState(new Date());
    const [expiredDate, setExpiredDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateAcceed, setDateAcceed] = useState(false);
    const [linkAccess, setLinkAccess] = useState(false)

    const [image, setImage] = useState("");
    const [errors, setErrors] = useState({});
    const [modal_id, setModal_id] = useState("");

    // Modal Window States
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        if (e.target.id) {
            setModal_id((e.target.id).replace("viewTemplate-", ""))
        }
        setShow(true);
    }
    // Next Step function
    const handleNextStep = (e) => {
        setStep(step + 1);

    }
    //Back Step function
    const handleBackStep = (e) => {
        e.preventDefault();
        setStep(step - 1);

    }
    /* Create New Audit Functions */
    const [createAudit, setCreateAudit] = useState({
        audit_name: '',
        created_at: '',
        expired_at: '',
        audit_description: '',
        audit_logo: '',
        audit_logo_path: '',
        audit_logo_name: '',
        status: "draft",
        audit_speech_detials: '',
        audit_pdf: '',
        audit_pdf_path: '',
        template_id: '',
        template_name: '',
        template_link: '',
        link_value: '',
        audit_token: '',
        user_id: user_id
    });

    const [editAudit, setEditAudit] = useState(true);

    const handleCreatedAt = createDate => {
        setCreateDate(createDate)
        setExpiredDate(createDate)
        setCreateAudit({ ...createAudit, 'created_at': format(createDate, 'dd/MM/yyyy') });

        //if (format(expiredDate, 'dd/MM/yyyy') <= format(createDate, 'dd/MM/yyyy')) {
        setDateAcceed(true)
        // }
        // else {
        //     setDateAcceed(false)
        // }
    }
    const handleExpiredDate = expiredDate => {
        setExpiredDate(expiredDate)
        setSelectedDate(expiredDate)
        setCreateAudit({ ...createAudit, 'expired_at': format(expiredDate, 'dd/MM/yyyy') });
    }

    // Chnage Input function
    const handleChangeAudit = (e) => {
        setCreateAudit({ ...createAudit, [e.target.name]: e.target.value });
        setEditAudit(false)

    }

    //Upload Logo function
    const [imageUrl, setImageUrl] = useState("");
    const handleUploadLogo = (e) => {
        setImage(e.target.files[0]);
        setImageUrl(URL.createObjectURL(e.target.files[0]));
        setCreateAudit({ ...createAudit, [e.target.name]: e.target.files[0], audit_logo_name: e.target.files[0].name });
    }


    //Save Audit function
    const handleSaveAudit = (e) => {
        e.preventDefault();
        setErrors(Validation(createAudit));
        if (Object.keys(errors).length === 0 && createAudit.audit_name && createAudit.audit_description && createAudit.created_at && createAudit.expired_at) {
            localStorage.setItem("create_audit", JSON.stringify(createAudit))

            handleNextStep();
        }
    }

    /* Keynote Speech tab Functions */
    const [pdfFile, setPdfFile] = useState('');
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfUpload, setPdfUpload] = useState(false);

    // Set values
    const handleChange = (e) => {
        setCreateAudit({ ...createAudit, [e.target.name]: e.target.value })
    }
    // Upload PDF function
    const handleUpload = (e) => {
        let selectedFile = e.target.files[0]
        setPdfFile(e.target.files[0]);
        // setPdfUrl(URL.createObjectURL(e.target.files[0]));
        setCreateAudit({ audit_pdf: '' });
        if (selectedFile) {
            setPdfUrl(URL.createObjectURL(e.target.files[0]));
            setPdfUpload(true)
            // let reader = new FileReader();
            // reader.readAsDataURL(selectedFile);
            // reader.onloadend = (e) => {
            //     setPdfUrl(e.target.result);
            //     setPdfUpload(true)
            // }
        }
        setCreateAudit({ ...createAudit, [e.target.name]: e.target.files[0], audit_speech_detials: e.target.files[0].name });
    }
    const handleRemovePdf = (e) => {
        e.preventDefault();
        setCreateAudit({ ...createAudit, audit_pdf: '', audit_speech_detials: '' })
        setPdfUpload(false);
    }
    // Form Submit
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidationKey(createAudit));
        if (Object.keys(errors).length === 0 && (createAudit.audit_speech_detials || createAudit.audit_pdf_path)) {
            localStorage.setItem("create_audit", JSON.stringify(createAudit))
            handleNextStep();
        }
    }

    /* Use Template tab Functions */
    // Select Template
    const handleSelectTemplate = (e) => {
        setCreateAudit({ ...createAudit, template_name: e.target.value, template_id: e.target.id })
    }
    const handleSelectButton = (e) => {
        setCreateAudit({ ...createAudit, template_name: e.target.id, template_id: e.target.name, audit_selectedName: e.target.id })
    }
    // Submit Template
    const handleSubmitTemplate = (e) => {

        e.preventDefault();
        setErrors(ValidationTemplate(createAudit));
        if (Object.keys(errors).length === 0 && createAudit.template_name && createAudit.template_id) {
            localStorage.setItem("create_audit", JSON.stringify(createAudit))
            handleNextStep();
            setLinkAccess(true)
        }
    }

    /* Generate tab functions */
    const [token, setToken] = useState("");
    const [templateLink, setTemplateLink] = useState("");
    const [linkId, setLinkId] = useState("");
    const handleLink = (e) => {
        e.preventDefault();
        setCreateAudit({ ...createAudit, link_value: 1, template_link: templateLink, link_id: linkId })

    }
    const handleCreateLink = (e) => {
        e.preventDefault();
        setCreateAudit({ ...createAudit, link_value: 2, template_link: "" })
    }
    const handleGenerateToken = (e) => {
        e.preventDefault();
        setCreateAudit({ ...createAudit, audit_token: token })
    }
    const handleChangeGenerateData = (e) => {
        const createLink = e.target.value.replace(/[^\w\s]/gi, "")
        setCreateAudit({ ...createAudit, template_link: e.target.value, link:createLink})
    }
    const handleSaveGenerateData = (e) => {
        e.preventDefault();
        if (id && createAudit.link_value === 2) {
            setCreateAudit({ ...createAudit, template_link: "https://audit.teamactionmanagement.com/" + createAudit.link })
        }
        else {
            if (createAudit.link_value === 2) {
                setCreateAudit({ ...createAudit, template_link: "https://audit.teamactionmanagement.com/" + createAudit.link })
            }
        }
        setErrors(ValidationLink(createAudit));
        if (Object.keys(errors).length === 0 && createAudit.template_link && createAudit.audit_token) {
            localStorage.setItem("create_audit", JSON.stringify(createAudit))
            handleNextStep();

        }
    }

    /* Review tab Functions */
    const handleReview = (e) => {
        e.preventDefault();
        setErrors(ValidationShare(createAudit))
        if (Object.keys(errors).length === 0) {
            const data = new FormData()

            data.append('audit_name', createAudit.audit_name)
            data.append('created_at', createAudit.created_at)
            data.append('updated_at', createAudit.expired_at)
            data.append('audit_description', createAudit.audit_description)
            data.append('audit_logo', createAudit.audit_logo)
            data.append('user_id', user_id)
            data.append('status', "Active")
            data.append('audit_speech_detials', createAudit.audit_speech_detials)
            data.append('audit_pdf', pdfFile)
            data.append('template_id', createAudit.template_id)
            data.append('template_name', createAudit.template_name)
            data.append('template_link', createAudit.template_link)
            data.append('link_value', createAudit.link_value)
            data.append('audit_token', createAudit.audit_token)
            data.append('link_id', createAudit.link_id)
            if ((window.event.submitter.name === "SaveAsDraft")) {
                data.append('status', "Draft")
            }
            if (id) {
                data.append('_method', "put")
                http.post(`/useraudit/${id}`, data, {
                    headers: {
                        "Content-Type": "Multipart/form-data"
                    }
                })
                    .then(function (response) {
                        if ((response.data.data.status === "Draft")) {
                            toast.warning('Your Data have been saved as Draft!', {
                                position: "top-center"
                            });
                        }
                        else {
                            toast.success('Data Updated Succussfully', {
                                position: "top-center"
                            });
                        }
                        setTimeout(() => {
                            window.localStorage.removeItem('create_audit');
                            setEditAudit(true)
                            navigate('/dashboard');
                        }, 2000);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            else {
                http.post('/useraudit', data, {
                    headers: {
                        "Content-Type": "Multipart/form-data"
                    }
                })
                    .then(function (response) {
                        toast.success('Data Submitted Succussfully', {
                            position: "top-center"
                        });
                        setTimeout(() => {
                            window.localStorage.removeItem('create_audit');
                            navigate('/dashboard');
                        }, 2000);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }

        }

    }



    useEffect(() => {

        if (dateAcceed) {
            setExpiredDate(createDate.setDate(createDate.getDate() + 1))
            setSelectedDate(createDate.setDate(createDate.getDate()))
            setCreateAudit({ ...createAudit, 'expired_at': format(createDate, 'dd/MM/yyyy') });
            setDateAcceed(false)
            setCreateDate(createDate.setDate(createDate.getDate() - 1))
        }

        if (linkAccess && !templateLink && !token) {
            http.get("/linktoken")
                .then((response) => {
                    // setCreateAudit({ ...createAudit, link_value: 1, template_link: response.data.data.template_link, link_id: response.data.data.id })
                    setToken(response.data.data.template_token);
                    setTemplateLink(response.data.data.template_link)
                    setLinkId(response.data.data.id)
                    setLinkAccess(false)
                })
        }
        if (createAudit.audit_name === "" && !id) {
            if (editAudit) {
                const getAudit = JSON.parse(window.localStorage.getItem('create_audit'));
                if (getAudit && !getAudit.id) {
                    setCreateAudit(getAudit)
                    setEditAudit(false)
                }
            }
        }
        if (imageUrl && (createAudit.audit_logo_path === "" || imageUrl !== createAudit.audit_logo_path)) {
            setCreateAudit({ ...createAudit, audit_logo_path: imageUrl });
        }
        if (pdfUrl && pdfUpload) {
            setCreateAudit({ ...createAudit, audit_pdf: pdfUrl });
            setPdfUpload(false)
            // pdfFile.length > 0 && setPdfUrl(URL.createObjectURL(pdfFile[0]));
        }

        if (id && editAudit && createAudit.audit_name === "") {
            localStorage.removeItem('create_audit');
            const getAudit = JSON.parse(window.localStorage.getItem('create_audit'));
            if (!getAudit) {

                http.get(`/useraudit/${id}`)
                    .then(function (response) {
                        let editAuditData = response.data.data;
                        if (editAuditData) {

                            const createTime = editAuditData.created_at;
                            const expireTime = editAuditData.expired_at;

                            const createDateText = parseInt(createTime.slice(0, 2))
                            const createMonthText = parseInt(createTime.slice(3, 5))
                            const createYearText = parseInt(createTime.slice(6, 10))

                            const expireDateText = parseInt(expireTime.slice(0, 2))
                            const expireMonthText = parseInt(expireTime.slice(3, 5))
                            const expireYearText = parseInt(expireTime.slice(6, 10))

                            const dateSet = createMonthText + "/" + createDateText + "/" + createYearText;
                            const exDateSet = createMonthText + "/" + (createDateText + 1) + "/" + createYearText;
                            const selectDateSet = expireMonthText + "/" + expireDateText + "/" + expireYearText;
                            setCreateAudit(editAuditData)
                            setPdfFile(editAuditData.audit_pdf)
                            // setCreateDate(new Date(format(createTime, 'dd/MM/yyyy')))
                            // setExpiredDate(new Date(format(expireTime, 'dd/MM/yyyy')))
                            setCreateDate(new Date(dateSet))
                            setExpiredDate(new Date(exDateSet))
                            setSelectedDate(new Date(selectDateSet))
                            setEditAudit(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            else {
                setCreateAudit(getAudit)
            }
        }
        // if (id && createAudit.audit_name !== "") {
        //     setEditAudit(false)
        // }
    }, [image, imageUrl, pdfFile, pdfUrl, createAudit, id, createDate, expiredDate, editAudit, user_id, pdfUpload, dateAcceed, linkAccess, templateLink, token, selectedDate, http]);


    switch (step) {
        case 1:
            return (
                <NewAudit
                    handleChangeAudit={handleChangeAudit}
                    handleUploadLogo={handleUploadLogo}
                    handleSaveAudit={handleSaveAudit}
                    createAudit={createAudit}
                    handleCreatedAt={handleCreatedAt}
                    createDate={createDate}
                    handleExpiredDate={handleExpiredDate}
                    expiredDate={expiredDate}
                    selectedDate={selectedDate}
                    errors={errors}
                />
            )
        case 2:
            return (
                <KeyNoteSpeech
                    createAudit={createAudit}
                    errors={errors}
                    handleChange={handleChange}
                    handleUpload={handleUpload}
                    handleSubmit={handleSubmit}
                    handleBackStep={handleBackStep}
                    handleRemovePdf={handleRemovePdf}
                />
            )
        case 3:
            return (
                <UseTemplates
                    show={show}
                    errors={errors}
                    modal_id={modal_id}
                    selectTemplate={createAudit}
                    handleSelectButton={handleSelectButton}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    handleBackStep={handleBackStep}
                    handleSelectTemplate={handleSelectTemplate}
                    handleSubmitTemplate={handleSubmitTemplate}
                />
            )
        case 4:
            return (
                <GenerateLink
                    errors={errors}
                    handleBackStep={handleBackStep}
                    generateData={createAudit}
                    handleLink={handleLink}
                    handleCreateLink={handleCreateLink}
                    handleGenerateToken={handleGenerateToken}
                    handleChangeGenerateData={handleChangeGenerateData}
                    handleSaveGenerateData={handleSaveGenerateData}
                />
            )
        case 5:
            return (
                <ReviewShare
                    errors={errors}
                    assetsUrl={assetsUrl}
                    reviewData={createAudit}
                    handleBackStep={handleBackStep}
                    handleReview={handleReview}
                />
            )
        // never forget the default case, otherwise VS code would be mad!
        default:
        // do nothing
    }
}

export default MainStep;