import React, { Fragment } from "react";

const GenerateLink = ({ errors, handleBackStep, generateData, handleLink, handleCreateLink, handleGenerateToken, handleChangeGenerateData, handleSaveGenerateData }) => {

    if (generateData.link_value === 2 && generateData.id) {
        generateData.link = generateData.template_link.replace("https://audit.teamactionmanagement.com/", "")
    }
    else {
        if(generateData.link_value === 1)
        generateData.link = generateData.template_link;
    }
    return (
        <Fragment>
            <div className="col-md-3">
                {/* Tab navs */}
                <div className="nav flex-column nav-tabs text-center New-auditTabs" id="v-tabs-tab">
                    <span className="nav-link">Create new audit</span>
                    <span className="nav-link">Keynote speech</span>
                    <span className="nav-link">Use forms/templates</span>
                    <span className="nav-link active">Generate link</span>
                    <span className="nav-link">Review &amp; Save</span>
                </div>
                {/* Tab navs */}
            </div>
            <div className="col-md-9">
                <div className="Audit-form-div">
                    {/* Tab content */}
                    <div className="tab-content">
                        {/* Start Generate Link*/}
                        <div className="tab-pane active" id="v-tabs-link" role="tabpanel">
                            <div className="audit-form">
                                <div className="audit-header">
                                    <h5 className="audit-title">Generate Link</h5>
                                </div>
                                <div className="audit-form-content">
                                    <form onSubmit={handleSaveGenerateData}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className={generateData.link_value === 2 ? "custom-link" : ""}>
                                                        <label>Generate Unique Link</label>
                                                        <input type="text" name="link" className="form-control" value={generateData.link || ''} readOnly={generateData.link_value < 2 || generateData.id ? "readOnly" : ""} onChange={handleChangeGenerateData} />
                                                        {generateData.link_value === 2 ? <span>https://audit.teamactionmanagement.com/</span> : null}
                                                    </div>
                                                    {errors.link && <p style={{ color: "red" }}>{errors.link}</p>}
                                                    {generateData.link_value === 2 && !generateData.id ? <mark className="mt-1 d-inline-block">Link example :- (https://audit.teamactionmanagement.com/my_own_title_for_audit)</mark> : ""}

                                                </div>
                                                <div className="text-left d-block generate-form-btn mt-3 mb-3">
                                                    <button type="button" className="mr-2 text-generate btn" onClick={handleLink} disabled={generateData.id ? "disabled" : ""}>Generate Link</button>
                                                    <button type="button" className="text-own btn" onClick={handleCreateLink} disabled={generateData.id ? "disabled" : ""}>Create your own</button>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Token ID</label>
                                                    <input type="text" name="token" className="form-control" value={generateData.audit_token || ''} readOnly />
                                                    {errors.token && <p style={{ color: "red" }}>{errors.token}</p>}
                                                </div>
                                                <div className="text-left d-block generate-form-btn mt-3">
                                                    <button type="button" className="mr-2 text-generate btn" onClick={handleGenerateToken} disabled={generateData.id ? "disabled" : ""}>Generate Token ID</button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* start footer buttons*/}
                                        <div className="col-md-12 px-0">
                                            <div className="text-right d-block audit-form-footer">
                                                <button type="button" className="mr-2 text-Bck btn" onClick={handleBackStep}>Back</button>
                                                <button type="submit" className="mr-2 text-Next btn">Save &amp; Next</button>
                                                {/* <button type="button" className="mr-2 text-Next btn" disabled>Save as Draft</button>
                                                <button type="button" className="text-Share btn" disabled>Share Audit</button> */}
                                            </div>
                                        </div>
                                        {/* End footer buttons*/}
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* End Generate Link*/}
                    </div>
                    {/* Tab content */}
                </div>
            </div>

        </Fragment>
    )
}
export default GenerateLink;