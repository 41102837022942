import React, { useEffect, useState } from 'react';
import Login from './components/Auth/Login';
import Dashboard from './components/Dashboard';
import CreateAudit from './components/CreateAudit';
import Templates from './components/Templates';
import Profile from './components/Profile';
import Forgotpassword from './components/Auth/Forgotpassword';
import Resetpin from './components/Auth/Resetpin'
import Resetpassword from './components/Auth/Resetpassword'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import AuditDetails from './components/AuditDetails';
import UserForm from './components/UserForm';
import ThankyouPage from './components/ThanksYou';
import CreateTemplate from './components/CreateTemplate';

import { useIdleTimer } from 'react-idle-timer'
import { Button, Modal } from 'react-bootstrap';

import AuthUser from './components/Auth/AuthUser';


function App() {

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const pathID = path.replace("/create-audit/", "")
  const auditID = path.replace("/auditDetails/", "")
  // console.log(path, "path id "+pathID)
  const { token } = AuthUser();


  // Set timeout values
  const timeout = 1000 * 60 * 30;
  const promptBeforeIdle = 1000 * 30;

  // Modal open state
  const [open, setOpen] = useState(false)

  // Time before idle
  const [remaining, setRemaining] = useState(0)

  const onPrompt = () => {
    if (path === "/dashboard" || path === "/create-audit" || path === `/create-audit/${pathID}` || path === `/auditDetails/${auditID}` || path === "/templates" || path === "/profile" || path === "/viewTemplate") {
      setOpen(true)
      setRemaining(promptBeforeIdle / 1000)
    }
  }

  const onIdle = () => {
    if (path === "/dashboard" || path === "/create-audit" || path === "/create-audit/:id" || path === "/auditDetails/:id" || path === "/templates" || path === "/profile" || path === "/viewTemplate") {
      setOpen(false)
      setRemaining(0)
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('user_id');
      navigate('/', { replace: false });
    }
  }

  const onActive = () => {
    setOpen(false)
    setRemaining(0)
  }

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    onActive,
  })
  const handleStillHere = () => {
    setOpen(false)
    activate()
  }

  useEffect(() => {
    // Login functionality
    if (token) {
      if (path === "/") {
        navigate("/dashboard")
      }
    }
    else {
      if (path === "/dashboard" || path === "/create-audit" || path === "/create-audit/:id" || path === "/auditDetails/:id" || path === "/templates" || path === "/profile" || path === "/viewTemplate") {
          navigate("/", { replace: false })
      }
    }
    // Logged out if user idle before 30 mins
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }


  }, [getRemainingTime, isPrompted, token])

  return (
    <>
      <Modal
        show={open}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        {/* <Modal.Header closeButton>
                    <Modal.Title><h4></h4></Modal.Title>
                </Modal.Header> */}
        <Modal.Body>
          <p>Logging you out in {remaining} seconds</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleStillHere}>Im Still Here</Button>

        </Modal.Footer>
      </Modal>


      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/create-audit" element={<CreateAudit />} />
        <Route path="/create-audit/:id" element={<CreateAudit />} />
        <Route path="/viewTemplate" element={<CreateTemplate />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route path="/resetpin" element={<Resetpin />} />
        <Route path="/resetpassword" element={<Resetpassword />} />
        <Route path="/auditDetails/:id" element={<AuditDetails />} />
        <Route path="*" element={<UserForm />} />
        <Route path="/thankyou" element={<ThankyouPage />} />
      </Routes>
    </>

  );
}

export default App;
