// Validation for New Create Audit tab
export const Validation = (values) => {
    let errors = {}

    if (!values.audit_name) {
        errors.audit_name = "Please enter Audit Name";
    }
    if (!values.created_at) {
        errors.created_at = "Please select create date";
    }
    if (!values.expired_at) {
        errors.expired_at = "Please select expired date";
    }
    if (!values.audit_description) {
        errors.audit_description = "Please enter Audit Description";
    }
    return errors;
}

// Validation for Keynote Speech tab
export const ValidationKey = (values) => {
    let errors = {}
    if (!values.audit_speech_detials) {
        errors.audit_speech_detials = "Please upload any Pdf file.";
    }
    return errors;
}

// Validation for Use Form/Template tab
export const ValidationTemplate = (values) => {
    let errors = {}

    if (!values.template_name && !values.template_id) {
        errors.name = "Please select any one Template";
    }
    return errors;
}

// Validation for Generate Link tab
export const ValidationLink = (values) => {
    let errors = {}

    if (!values.template_link) {
        errors.link = "Please select Generate Link or Create Link";
    }
    if (!values.audit_token) {
        errors.token = "Please create Token";
    }
    return errors;
}

// Validation for Review tab
export const ValidationShare = (values) => {
    let errors = {}
    if (!values.name) {
        errors.audit_name = "Please enter Audit name";
    }
    if (!values.audit_speech_detials && !values.audit_pdf_path) {
        errors.speech = "Please enter Keynote Speech";
    }
    if (!values.template_name) {
        errors.template = "Please enter Form";
    }
    if (!values.template_link) {
        errors.link = "Please enter Audit Link";
    }
    return errors;
}