import React, { Fragment, useState } from "react";

import './emailModule.css';

const CreateTemplate = () => {
    // Styles
    const bgColor = {
        background: '#f4f6f9'
    }
    const header = {
        backgroundColor: '#faeecf',
        width: '800px',
        padding: '12px 15px',
        display: 'inline-block'
    }

    const [template, setTemplate] = useState("");
    const [templateID, setTemplateID] = useState("");
    if (!template) {
        const audit = JSON.parse(localStorage.getItem('create_audit'));
        setTemplate(audit);
    }
    if (!templateID) {
        setTemplateID(parseInt(template.template_id))
    }
    if (templateID === 2)
        header.backgroundColor = '#d2e8e7'
    if (templateID === 3)
        header.backgroundColor = '#c9e2f6'
    if (templateID === 4)
        header.backgroundColor = '#f4e0dd'
    if (templateID === 5)
        header.backgroundColor = '#e7ded9'


    return (
        <Fragment>
            <table className={`form-view form-${template.template_id}`} cellPadding={0} cellSpacing={0} border={0} width={800} style={{ minWidth: '700px', margin: '0 auto', backgroundColor: '#fff', fontFamily: '"Roboto",sans-serif' }}>
                <tbody>
                    <tr>
                        <td style={bgColor}>
                            <table cellPadding={0} cellSpacing={0} border={0} width={700} style={{ minWidth: '700px', margin: '0 auto', backgroundColor: '#fff', fontFamily: '"Roboto",sans-serif' }}>
                                <tbody><tr>
                                    <td width={800} style={bgColor}>
                                        <div style={header}>
                                            <div style={{ width: '32%', float: 'left' }}>
                                                <img className="img-fluid" src={template.audit_logo_name ? `/assets/images/${template.audit_logo_name}` : "/assets/images/pwrd-logo.png"} alt="logo" style={{ maxWidth: '204px', maxHeight: 100 }} />
                                            </div>
                                            <div style={{ width: '26%', float: 'right', textAlign: 'right' }}>
                                                <img className="img-fluid" src="/assets/images/pwrd-logo.png" alt="logo" style={{ width: '204px' }} />
                                            </div>
                                        </div>
                                        <form>
                                            <div className="row" style={{ margin: 0, padding: '15px' }}>
                                                <div className="col-12 px-0">
                                                    <h4>Team Action Management</h4>
                                                    <p>Delivers Successful Change &amp; Increase The Performance To All People-Based Organizations</p>
                                                    <h4>Keynote Speech</h4>
                                                    <p>{template.audit_speech_detials} <a className="ml-5 text-underline" href={template.id ? template.audit_pdf.includes("public") ? `https://api.teamactionmanagement.com/storage${template.audit_pdf.replace("public", "")}`:"" : template.audit_pdf} target="_blank">View Keynote Speech</a></p>
                                                    <div className="breadcrumb vt-a">
                                                        <a href="#"> Form {template.template_id}: Your Views/Opinions</a>
                                                    </div>
                                                </div>

                                                <div className="col-12 px-0">
                                                    <div className="form-group">
                                                        <label>Please give a short title </label>
                                                        <span>
                                                            <input type="text" className="form-control" placeholder="Title" name="title" value="" readOnly />

                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 px-0">
                                                    <div className="same_field_box red-border">
                                                        <label>Describe which box your title refers to &amp; tick </label>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                <h6>Present</h6>
                                                                <div className="custom-radio">
                                                                    <input type="radio" name="refers_to" id="Strength" value="Strength" disabled />
                                                                    <label htmlFor="Strength">Strength</label>
                                                                </div>
                                                                <div className="custom-radio">
                                                                    <input type="radio" name="refers_to" id="Fault" value="Fault" disabled />
                                                                    <label htmlFor="Fault">Fault</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                <h6>Future</h6>
                                                                <div className="custom-radio">
                                                                    <input type="radio" name="refers_to" id="Opportunity" value="Opportunity" disabled />
                                                                    <label htmlFor="Opportunity">Opportunity</label>
                                                                </div>
                                                                <div className="custom-radio">
                                                                    <input type="radio" name="refers_to" id="Threat" value="Threat" disabled />
                                                                    <label htmlFor="Threat">Threat</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-12 px-0">
                                                    <label>Statement of your view or opinion - 1 per form please -</label>
                                                    <div className="text_field_box spacing-top">
                                                        <textarea placeholder="Enter text" name="statement_view" readOnly />
                                                    </div>

                                                </div>
                                                <div className="col-12 px-0">
                                                    <label>Do you want to give an example?</label>
                                                    <div className="text_field_box spacing-top">
                                                        <textarea placeholder="Enter text" name="example" readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-12 px-0">
                                                    <label>What could be done -</label>
                                                    <div className="text_field_box spacing-top">
                                                        <textarea placeholder="Enter text" name="done" readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer text-right">
                                                <input type="button" className="btn btn-primary Cancel-btn" value="Cancel" />
                                                <input type="button" className="btn btn-primary" value="Submit" />
                                            </div>
                                        </form>
                                        <div className="footer-bottom">
                                            <p className="footer-text">Unleashing the power</p>
                                            <p> TAM Uk © 2023, Team Action Management <span style={{ textAlign: 'right' }}>To Re-Order _______ Form No. 1001</span></p>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>


        </Fragment >
    )
}

export default CreateTemplate;