const Validation = (values) => {
    let errors = {}


    if (!values.password) {
        errors.password = "Password is required!";
    } else if (values.password.length < 6) {
        errors.password = "Password must be 6 character!";
    }
    if (!values.c_password) {
        errors.c_password = "Confirm Password is required!";
    } else if (values.c_password.length < 6) {
        errors.c_password = "Confirm Password must be 6 character!";
    }

    if (values.c_password !== values.password) {
        errors.c_password = "Password not match";
    }
    return errors;
}
export default Validation;