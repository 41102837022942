import React, { Fragment } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const NewAudit = ({ handleChangeAudit, handleUploadLogo, handleSaveAudit, createAudit, handleCreatedAt, createDate, handleExpiredDate, expiredDate,selectedDate, errors }) => {

    return (
        <Fragment>
            <div className="col-md-3">
                {/* Tab navs */}
                <div className="nav flex-column nav-tabs text-center New-auditTabs">
                    <span className="nav-link active">Create new audit</span>
                    <span className="nav-link">Keynote speech</span>
                    <span className="nav-link">Use forms/templates</span>
                    <span className="nav-link">Generate link</span>
                    <span className="nav-link">Review &amp; Save</span>
                </div>
                {/* Tab navs */}
            </div>
            <div className="col-md-9">
                <div className="Audit-form-div">
                    {/* Tab content */}
                    <div className="tab-content">
                        {/* Start New Audit*/}
                        <div className="tab-pane active">
                            <div className="audit-form">
                                <div className="audit-header">
                                    <h5 className="audit-title">New Audit</h5>
                                </div>
                                <div className="audit-form-content">
                                    <form onSubmit={handleSaveAudit} encType="multipart/form-data">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Audit Name</label>
                                                    <input type="text" className="form-control" name='audit_name' value={createAudit.audit_name || ""} onChange={handleChangeAudit} />
                                                    {errors.audit_name && <p style={{ color: "red" }}>{errors.audit_name}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Created Date</label>
                                                    <DatePicker autoComplete="off" selected={createDate} minDate={new Date()} className="form-control" onChange={handleCreatedAt} name='created_at' value={createAudit.created_at || ""} format="y-MM-dd"  />
                                                    {errors.created_at && <p style={{ color: "red" }}>{errors.created_at}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Expired Date</label>
                                                    <DatePicker autoComplete="off" selected={selectedDate} minDate={expiredDate} className="form-control" onChange={handleExpiredDate} name='expired_at' value={createAudit.expired_at || ""} format="y-MM-dd" />
                                                    {errors.expired_at && <p style={{ color: "red" }}>{errors.expired_at}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Audit Description</label>
                                                    <textarea className="form-control" id="textAreaExample2" rows={8} name='audit_description' value={createAudit.audit_description || ""} onChange={handleChangeAudit} />
                                                    {errors.audit_description && <p style={{ color: "red" }}>{errors.audit_description}</p>}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label>Upload Logo</label>
                                                        <input type="text" placeholder="Company logo can be uploaded here" className="form-control" readOnly value={createAudit.audit_logo_name || ""} />
                                                        <div className="input-group-btn">
                                                            <span className="fileUpload">
                                                                <img alt='Upload' src="/assets/images/upload.png" className="img-fluid" />
                                                                <input type="file" className="upload up" id="up" name='audit_logo' onChange={handleUploadLogo} accept="image/x-png,image/gif,image/jpeg" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className="c-logo text-center">
                                                            {createAudit.audit_logo_name ? "" : (createAudit.audit_logo && createAudit.audit_logo !== "null"  && createAudit.id) ? <img src={`https://api.teamactionmanagement.com/storage${createAudit.audit_logo.replace("public", "")}`} alt="Logo" className="img-fluid" /> : ""}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* start footer buttons*/}
                                        <div className="col-md-12 px-0">
                                            <div className="text-right d-block audit-form-footer">
                                                {/* <button type="button" className="mr-2 text-Bck btn">Back</button> */}
                                                <button type="submit" className="mr-2 text-Next btn">Save &amp; Next</button>
                                                {/* <button type="button" className="mr-2 text-Next btn" disabled>Save as Draft</button>
                                                <button type="button" className="text-Share btn" disabled>Share Audit</button> */}
                                            </div>
                                        </div>
                                        {/* End footer buttons*/}
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* Start end Audit*/}
                    </div>
                    {/* Tab content */}
                </div>
            </div>
        </Fragment>
    )
}

export default NewAudit;