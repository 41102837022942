import React, { Fragment, useEffect, useState } from "react";
import './SideBar';
import './Footer';
import validation from './LoginValidation';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from './AuthUser';
import SideBar from "./SideBar";
import Footer from "./Footer";
import Loader from "./Loader";
export default function Login() {
    const navigate = useNavigate();
    const { http, setToken } = AuthUser();

    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState(false);


    const [values, setValues] = useState({
        email: '',
        password: ''
    });

    const [errors, setErrors] = useState({});

    function handleChange(e) {
        setValues({ ...values, [e.target.name]: e.target.value });
        setChecked(false);
    }

    function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        setErrors(validation(values));
    }
    function handleForgotPassword(e) {
        e.preventDefault();
        setIsLoading(true)
        setTimeout(() => {
            navigate("/forgotpassword")
            setIsLoading(false);
        }, 1000);
    }

    function handleRemberMe(e) {
        setChecked(!checked)
        const isChecked = e.target.checked;
        if (isChecked) {
            const loginUser = { username: values.email, password: values.password }
            localStorage.setItem("loginUser", JSON.stringify(loginUser));
        }
    }

    function handleRemeberUser() {
        const remberUser = JSON.parse(localStorage.getItem('loginUser'));
        if (remberUser) {
            setValues({ email: remberUser.username, password: remberUser.password });
        }
    }

    useEffect(() => {
        handleRemeberUser();
        if (Object.keys(errors).length === 0 && (values.email !== "" && values.password !== "")) {

            http.post('/login', { email: values.email, password: values.password }).then((res) => {
                if (res.data.success === false) {
                    setIsLoading(false);
                    toast.error(res.data.message, {
                        position: "top-center"
                    });
                    navigate('/', { replace: false });
                }
                else if (res.data.token !== 'Unauthorised') {
                    setIsLoading(false);
                    toast.error("Login Successfully", {
                        position: "top-center"
                    });

                    setToken(res.data.data.name, res.data.data.token, res.data.data.user_id);
                }

            }).catch(err => {
                setIsLoading(false);
                toast.error(err.message, {
                    position: "top-center"
                });
                navigate('/', { replace: false });

            });
        } else {
            setIsLoading(false);
        }
    }, [errors]);

    return (
        <Fragment>
            {isLoading ? <Loader /> : ""}
            <div className="d-lg-flex half">
                <SideBar />
                <div className="contents">
                    <div className="container" data-aos="fade-right">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-11 LogInFrom  mt-5" >
                                {/* <div className="row mt-4 mb-4">
                                    <div className="col-md-6 col-sm-12">
                                        <p><Link to="/"> <i className="fa fa-arrow-left"></i>Back to the homepage</Link></p>
                                    </div>
                                    <div className="col-md-6 col-sm-12 ">
                                        <div className="col-md-12 top text-center">
                                            <span className="helper-text">Donot have an account? <a href="./register.html">Register from here <i className="fa fa-arrow-right" ></i></a></span>
                                        </div>
                                    </div>
                                </div> */}
                                <h1>Welcome back,</h1>
                                <p className="mb-4">Please login to your account.</p>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12 floating-label">
                                            <label htmlFor="email">Email Address</label>
                                            <input id="email" type="email" name="email" value={values.email} onChange={handleChange} />
                                            {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 floating-label">
                                            <label htmlFor="password">Password</label>
                                            <input id="password" type="password" name="password" value={values.password} onChange={handleChange} />
                                            {errors.password && <p style={{ color: "red" }}>{errors.password}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="d-flex mb-5 align-items-center">
                                            <div className="d-flex align-items-center">
                                                <label htmlFor="rememberMe" className="control control--checkbox mb-0">
                                                    <span className="caption">Remember Me</span>
                                                    <input name="rememberMe" id="rememberMe" type="checkbox" onChange={handleRemberMe} checked={checked} disabled={values.email === "" || values.password === ""} />
                                                    <div className="control__indicator"></div>
                                                </label>
                                            </div>
                                            <span className="ml-auto">
                                                <Link className="forgot-pass" to={"/"} onClick={handleForgotPassword}>Forgot Password?</Link>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3 LogButtons text-center">
                                        <button className="btn Register" type="submit">Log In</button>
                                    </div>
                                </form>

                                <Footer />
                                <ToastContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
