import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Header from './Header';
import Navbar from './Navbar';
import AuthUser from './Auth/AuthUser';
import { profileVaidation } from "./UserForm_Validation";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from 'react-bootstrap';
function Profile() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);
  const { token, user_id, http } = AuthUser();
  const [error, setError] = useState({});
  const [profile, setProfile] = useState({
    name: '',
    email: ''
  });
  const [profileFormData, setProfileFormData] = useState({
    name: '',
    password: '',
    password_confirmation: ''
  })
  const handleChangeProfile = (e) => {
    if (e.target.name === 'name') {
      e.target.value = e.target.value.replace(
        /[^a-zA-Z\s]/g,
        ""
      );
      
    }


    setProfileFormData({ ...profileFormData, [e.target.name]: e.target.value })
  }
  const handleSaveProfile = (e) => {
    e.preventDefault();
    setError(profileVaidation(profileFormData));
  }
  const profileCheck = () => {
    if (Object.keys(error).length === 0 && profileFormData.name !== "" && profileFormData.password !== "" && profileFormData.password_confirmation !== "") {

      const data = { name: profileFormData.name, password: profileFormData.password, password_confirmation: profileFormData.password_confirmation };

      http.put(`/user/${user_id}`, data, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        }
      })
        .then(function (response) {
          toast.success('Data have been Submitted Succussfully', {
            position: "top-center"
          });
          setProfileFormData({
            name: '',
            password: '',
            password_confirmation: ''
          });
          handleClose();
        })
        .catch(function (error) {
          console.log(error);
        });

    }
  }
  const handleSideBar = () => {
    document.body.classList.remove('offcanvas-active')
  }

  useEffect(() => {
    profileCheck();
    if (token === undefined || token === null) {

      navigate('/', { replace: false });
    }

    http.get(`/user/${user_id}`)
      .then(function (response) {
        let profileData = response.data.data[0];
        if (profileData) {

          setProfile(profileData)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [error]);
  return (
    <Fragment>
      <div id="wrapper">
        <Header />
        <div className="middlepart">
          <Navbar />
          <div id="main-content" onClick={handleSideBar}>
            <div className="container-fluid mb-4">
              <div className="Audicreate-div auditspacing">
                <div className="row clearfix borderBottom">
                  <div className="col-lg-12">
                    <div className="audits-title">
                      <h6><i className="fa fa-arrow-left mr-2" />Profile Details</h6>
                    </div>
                  </div>
                </div>
                <div className="row clearfix mt-3 ">
                  <div className="col-lg-12 ">

                    <div className="row">
                      <div className="col-md-6 floating-label">
                        <label>Name</label>
                        <input type="name" name="user_name" value={profile.name || ""} readOnly="readOnly" autoComplete="off" />
                      </div>
                      <div className="col-md-6 floating-label">
                        <label>Email Address</label>
                        <input type="email" name="user_email" value={profile.email || ""} readOnly="readOnly" autoComplete="off" />
                      </div>

                      <div className="col-md-12 mb-3 LogButtons text-center">
                        <a className="btn edit" onClick={handleOpen}>Edit</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className=""
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title><h4>Edit Profile Details</h4></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row clearfix">

                <div className="col-12 audit-form-content">
                  <form onSubmit={handleSaveProfile}>
                    <div className="row">
                      <div className="col-md-12 floating-label">
                        <label>Name</label>
                        <input type="name" name="name" autoComplete="off" value={profileFormData.name || ""} onChange={handleChangeProfile} />
                        {error.name && <p style={{ color: "red" }}>{error.name}</p>}
                      </div>

                      <div className="col-md-6 floating-label">
                        <label>Create Password</label>
                        <input type="password" name="password" autoComplete="off" value={profileFormData.password || ""} onChange={handleChangeProfile} />
                        {error.password && <p style={{ color: "red" }}>{error.password}</p>}
                      </div>
                      <div className="col-md-6 floating-label">
                        <label>Confirm Password</label>
                        <input type="password" name="password_confirmation" autoComplete="off" value={profileFormData.password_confirmation || ""} onChange={handleChangeProfile} />
                        {error.password_confirmation && <p style={{ color: "red" }}>{error.password_confirmation}</p>}
                      </div>
                    </div>
                    {/* start footer buttons*/}
                    <Modal.Footer className="col-md-10 mb-3 LogButtons text-center">
                      <a className="btn cancel mr-3" onClick={handleClose}>Cancel</a>

                      <Button className="btn update" type="submit">Update</Button>

                    </Modal.Footer>

                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>

        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
}

export default Profile;
