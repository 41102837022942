import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function AuthUser() {
    const navigate = useNavigate();

    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = () => {
        const userString = localStorage.getItem('user');
        const userDeatils = JSON.parse(userString);
        return userDeatils;
    }

    const getUserID = () => {
        const user_idString = localStorage.getItem('user_id');
        const userDeatils = JSON.parse(user_idString);
        return userDeatils;
    }

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());
    const [user_id, setUserID] = useState(getUserID());

    const saveToken = (user, token, user_id) => {
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('user_id', JSON.stringify(user_id));
        setToken(token);
        setUser(user);
        setUserID(user_id);
        navigate('/dashboard', { replace: false });
    }

    const http = axios.create({
        baseURL: "https://api.teamactionmanagement.com/api",
        headers: {
            "Content-Type": "application/json"
        }
    });
    return {
        setToken: saveToken,
        token,
        user,
        user_id,
        getToken,
        http
    }
}