import React, { Fragment, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from './Header';
import Navbar from './Navbar';
import AuthUser from './Auth/AuthUser';

import MainStep from './createAuditSteps/MainStep';
// import axios from 'axios';

function CreateAudit() {
  const handleSideBar = () => {
    document.body.classList.remove('offcanvas-active')
  }
  let { id } = useParams();
  const navigate = useNavigate();
  const { http } = AuthUser();
  const { token } = AuthUser();
  const { user_id } = AuthUser();
  useEffect(() => {
    if (token === undefined || token === null) {
      navigate('/', { replace: false });
    }
  });

  return (
    <Fragment>
      <div id="wrapper">
        <Header />
        <div className="middlepart">
          <Navbar />
          <div id="main-content" onClick={handleSideBar}>
            <div className="container mb-5">
              <div className="row w-100">
                <MainStep id={id} user_id={user_id} http={http} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CreateAudit;
