import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Validation, authValidation } from "./UserForm_Validation";
import { Modal, Button } from 'react-bootstrap';
import AuthUser from './Auth/AuthUser';
import './emailModule.css';

const UserForm = () => {
    const { http } = AuthUser();
    // Styles
    const bgColor = {
        background: '#f4f6f9'
    }
    const header = {
        backgroundColor: '#faeecf',
        width: '800px',
        padding: '12px 15px',
        display: 'inline-block'
    }

    // Modal Window States
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);

    const navigate = useNavigate();
    const location = useLocation();
    const pathID = location.pathname.split("&")[1];

    const [linkId, setLinkId] = useState();
    const [templateID, setTemplateID] = useState();
    const [status, setStatus] = useState("");
    const [token, setToken] = useState("");
    const [formAuth, setFormAuth] = useState(false);
    const [createdDate, setCreatedDate] = useState("");
    const [speechDetail, setSpeechDetail] = useState("");
    const [speechPDF, setSpeechPDF] = useState("");

    const [auditLogo, setAuditLogo] = useState("");
    // const [userID, setUserID] = useState("");

    const [auth, setAuth] = useState({
        name: '',
        email: '',
        token: ''
    })
    const [values, setValues] = useState({
        title: '',
        refers_to: '',
        statement_view: '',
        example: '',
        done: '',
        // termsAndConditions: false,
        link_id: pathID
    })
    const [errors, setErrors] = useState({});
    const [authErrors, setAuthErrors] = useState({});


    if (templateID === 1)
        header.backgroundColor = '#faeecf'
    if (templateID === 2)
        header.backgroundColor = '#d2e8e7'
    if (templateID === 3)
        header.backgroundColor = '#c9e2f6'
    if (templateID === 4)
        header.backgroundColor = '#f4e0dd'
    if (templateID === 5)
        header.backgroundColor = '#e7ded9'


    // const termsCondition = (e) => {
    //     //    setTerms(!terms)
    //     setValues({ ...values, [e.target.name]: e.target.checked })
    // }

    const handleChangeAuth = (e) => {
        setAuth({ ...auth, [e.target.name]: e.target.value })
    }

    const handleSaveAuth = (e) => {
        e.preventDefault();
        setAuthErrors(authValidation(auth, token));
    }
    const authenticationCheck = () => {
        // if (Object.keys(authErrors).length === 0 && !formAuth && auth.name !== "" && auth.email !== "" && auth.token !== "") {
        if (Object.keys(authErrors).length === 0 && !formAuth && auth.token !== "") {

            const data = new FormData()
            data.append('link_id', pathID)
            data.append('link_token', token)
            // data.append('email', auth.email)
            // data.append('name', auth.name)
            http.post('/linkuser', data)
                .then(function (response) {
                    setLinkId(response.data.data.id)
                    setFormAuth(true)
                    setShow(false);
                    setStatus("")
                    setToken("")

                })
                .catch(function (error) {
                    console.log(error);
                });
            setShow(false)
        }
    }
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(Validation(values))
        const data = new FormData()
        data.append('title', values.title)
        data.append('refers_to', values.refers_to)
        data.append('statement_view', values.statement_view)
        data.append('example', values.example)
        data.append('done', values.done)
        data.append('link_id', values.link_id)
        data.append('user_id', linkId)
        if (Object.keys(errors).length === 0 && values.title && values.refers_to && values.statement_view) {
            http.post('/templateuserdata', data)
                .then(function (response) {
                    toast.success('Data have been Submitted Succussfully', {
                        position: "top-center"
                    });
                    setValues({
                        title: '',
                        refers_to: '',
                        statement_view: '',
                        example: '',
                        done: '',
                        // termsAndConditions: false,
                        link_id: pathID
                    })
                    setTimeout(() => {
                        navigate(`/thankyou`, { state: location.pathname })
                    }, 2500)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        authenticationCheck();
        if (token === "") {
            http.get(`/userlinkdata/${pathID}/getLinkData`)
                .then((res) => {
                    setStatus(res.data.data[0].status)
                    setToken(res.data.data[0].audit_token)
                    setTemplateID(res.data.data[0].template_id)
                    setCreatedDate(res.data.data[0].created_at)
                    setAuditLogo(res.data.data[0].audit_logo)
                    // setUserID(res.data.data[0].user_id)
                    setSpeechDetail(res.data.data[0].audit_speech_detials)
                    setSpeechPDF(res.data.data[0].audit_pdf)
                })
                .catch(err => {
                    console.log(err)
                })

        }
    }, [token, authErrors, pathID])


    return (
        <Fragment>
            {/* Authentication Modal */}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className=""
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h4>Authentication</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row clearfix">
                        <div className="col-12">
                            <p>Please fill valid Information.</p>
                        </div>
                        <div className="col-12 audit-form-content">
                            <form onSubmit={handleSaveAuth}>
                                <div className="row">
                                    {/* <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" name='name' value={auth.name || ""} onChange={handleChangeAuth} />
                                            {authErrors.name && <p style={{ color: "red" }}>{authErrors.name}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" className="form-control" name='email' value={auth.email || ""} onChange={handleChangeAuth} />
                                            {authErrors.email && <p style={{ color: "red" }}>{authErrors.email}</p>}
                                        </div>
                                    </div> */}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Token</label>
                                            <input type="text" className="form-control" name='token' value={auth.token || ""} onChange={handleChangeAuth} />
                                            {authErrors.token && <p style={{ color: "red" }}>{authErrors.token}</p>}
                                        </div>
                                    </div>
                                </div>
                                {/* start footer buttons*/}
                                <Modal.Footer>
                                    <Button variant="primary" type="submit">Submit</Button>
                                </Modal.Footer>
                                {/* End footer buttons*/}
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {
                formAuth ? (
                    (status === "Active" || status === "active") ?
                        <table className={`form-view form-${templateID}`} cellPadding={0} cellSpacing={0} border={0} width={800} style={{ minWidth: '700px', margin: '0 auto', backgroundColor: '#fff', fontFamily: '"Roboto",sans-serif' }}>
                            <tbody>
                                <tr>
                                    <td style={bgColor}>
                                        <table cellPadding={0} cellSpacing={0} border={0} width={700} style={{ minWidth: '700px', margin: '0 auto', backgroundColor: '#fff', fontFamily: '"Roboto",sans-serif' }}>
                                            <tbody><tr>
                                                <td width={800} style={bgColor}>
                                                    <div style={header}>
                                                        <div style={{ width: '32%', float: 'left' }}>
                                                            <img src={auditLogo && auditLogo.includes("public") ? `https://api.teamactionmanagement.com/storage${auditLogo.replace("public", "")}` : "/assets/images/pwrd-logo.png"} style={{ maxWidth: '204px', maxHeight: 100 }} alt="logo" />
                                                        </div>
                                                        <div style={{ width: '26%', float: 'right', textAlign: 'right' }}>
                                                            <img src="/assets/images/pwrd-logo.png" style={{ width: '204px' }} alt="logo" />
                                                        </div>
                                                    </div>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="row" style={{ margin: 0, padding: '15px' }}>
                                                            <div className="col-12 px-0">
                                                                <h4>Team Action Management</h4>
                                                                <p>Delivers Successful Change &amp; Increase The Performance To All People-Based Organizations</p>
                                                                <h4>Keynote Speech</h4>
                                                                <p>{speechDetail} <a className="ml-5 text-underline" href={speechPDF.includes("public") ? `https://api.teamactionmanagement.com/storage${speechPDF.replace("public", "")}` : speechPDF} target="_blank" rel="noreferrer">View Keynote Speech</a></p>
                                                                <div className="breadcrumb vt-a">
                                                                    <a href="#"> Template {templateID}: Your Views/Opinions</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 px-0">
                                                                <div className="form-group">
                                                                    <label>Please give a short title </label>
                                                                    <span>
                                                                        <input type="text" className="form-control" placeholder="Title" name="title" value={values.title || ''} onChange={handleChange} />
                                                                        {errors.title && <p style={{ color: "red", marginTop: 5 }}>{errors.title}</p>}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 px-0">
                                                                <div className="same_field_box red-border">
                                                                    <label>Describe which box your title refers to &amp; tick </label>
                                                                    <div className="row mt-2">
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                            <h6>Present</h6>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="refers_to" id="Strength" value="Strength" onChange={handleChange} checked={values.refers_to === "Strength" ? "checked" : ""} />
                                                                                <label htmlFor="Strength">Strength</label>
                                                                            </div>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="refers_to" id="Fault" value="Fault" onChange={handleChange} checked={values.refers_to === "Fault" ? "checked" : ""} />
                                                                                <label htmlFor="Fault">Fault</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                            <h6>Future</h6>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="refers_to" id="Opportunity" value="Opportunity" onChange={handleChange} checked={values.refers_to === "Opportunity" ? "checked" : ""} />
                                                                                <label htmlFor="Opportunity">Opportunity</label>
                                                                            </div>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="refers_to" id="Threat" value="Threat" onChange={handleChange} checked={values.refers_to === "Threat" ? "checked" : ""} />
                                                                                <label htmlFor="Threat">Threat</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {errors.refers_to && <p style={{ color: "red", marginTop: -22, float: "left" }}>{errors.refers_to}</p>}
                                                            </div>
                                                            <div className="col-12 px-0">
                                                                <label>Statement of your view or opinion - 1 per form please -</label>
                                                                <div className="text_field_box spacing-top">
                                                                    <textarea placeholder="Enter text" name="statement_view" value={values.statement_view || ''} onChange={handleChange} />
                                                                </div>
                                                                {errors.statement_view && <p style={{ color: "red", marginTop: -15, float: "left" }}>{errors.statement_view}</p>}
                                                            </div>
                                                            <div className="col-12 px-0">
                                                                <label>Do you want to give an example?</label>
                                                                <div className="text_field_box spacing-top">
                                                                    <textarea placeholder="Enter text" name="example" value={values.example || ''} onChange={handleChange} />
                                                                </div>
                                                                {/* {errors.example && <p style={{ color: "red" }}>{errors.example}</p>} */}
                                                            </div>
                                                            <div className="col-12 px-0">
                                                                <label>What could be done -</label>
                                                                <div className="text_field_box spacing-top">
                                                                    <textarea placeholder="Enter text" name="done" value={values.done || ''} onChange={handleChange} />
                                                                </div>
                                                                {/* {errors.done && <p style={{ color: "red" }}>{errors.done}</p>} */}
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer text-right">
                                                            {/* <label className="control control--checkbox ">
                                                                <span className="caption">
                                                                    <a href="#">Terms &amp; Conditions</a>
                                                                </span>
                                                                <input type="checkbox" name="termsAndConditions" onChange={termsCondition} />
                                                                <div className="control__indicator" />
                                                            </label> */}
                                                            {/* <input type="button" className="btn btn-primary Cancel-btn" value="Cancel" /> */}
                                                            <input type="submit" className="btn btn-primary" value="Submit" />
                                                        </div>
                                                        {/* {errors.terms && <div className="col text-right" style={{ marginTop: -10 }}><p style={{ color: "red" }}>{errors.terms}</p></div>} */}
                                                    </form>
                                                    <div className="footer-bottom">
                                                        <p className="footer-text">Unleashing the power</p>
                                                        <p> TAM Uk © 2023, Team Action Management <span style={{ textAlign: 'right' }}>To Re-Order _______ Form No. 1001</span></p>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table> : (status === "Future" || status === "future") ? (<div className="templatesModal" id="templates-Modal">
                            <div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                            <h4 className="m-0">This link will be open at</h4>
                                            <h5 className="m-0 pl-3">{createdDate}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>) : (<div className="templatesModal" id="templates-Modal">
                            <div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                            <h4 className="m-0">Your Audit Status is:-</h4>
                                            <h5 className="m-0 pl-3">{status}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)) : ""
            }
            <ToastContainer />

        </Fragment >
    )
}

export default UserForm;