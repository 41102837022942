import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './SideBar';
import validation from './ForgotValidation';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from './AuthUser';
import SideBar from './SideBar';
function Forgotpassword() {
    const navigate = useNavigate();
    const { http } = AuthUser();
    const [errorMessage, setErrorMessage] = useState('');

    const [values, setValues] = useState({
        email: ''
    });

    const [errors, setErrors] = useState({});

    function handleChange(e) {
        setValues({ ...values, [e.target.name]: e.target.value })
    }
    function handleSubmit(e) {
        e.preventDefault();
        setErrors(validation(values));
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && values.email !== "") {

            http.post('/forgot-password', { email: values.email }).then((res) => {

                if (res.data.success === false) {
                    toast.error(res.data.message, {
                        position: "top-center"
                    });
                    navigate('/forgotpassword', { replace: false });
                }
                else {
                    toast.success(res.data.message, {
                        position: "top-center"
                    });
                    navigate('/forgotpassword', { replace: false });
                }

            }).catch((error) => {

                toast.error(error.response.data.message, {
                    position: "top-center"
                });
                // navigate('/forgotpassword', { replace: false });
            });
        }
    }, [errors]);


    return (
        <Fragment>
            <div className="d-lg-flex half">
                <SideBar />
                <div className="contents">
                    <div className="container" data-aos="fade-right">

                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-11 LogInFrom mt-5" >
                                {/* <div className="row mt-4 mb-4">
                                    <div className="col-md-6 col-sm-12">
                                        <p> <Link to="/"> <i className="fa fa-arrow-left"></i>Back to the homepage</Link></p>
                                    </div>
                                    <div className="col-md-6 col-sm-12 ">
                                        <div className="col-md-12 top text-center">
                                            <span className="helper-text">Don't have an account? <a href="./register.html">Register from here <i className="fa fa-arrow-right" ></i></a></span>
                                        </div>
                                    </div>
                                </div> */}
                                <h1>Forgot Password?</h1>
                                <p className="mb-4"></p>
                                {errorMessage && (
                                    <p style={{ color: "red" }}> {errorMessage} </p>
                                )}

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12 floating-label">
                                            <input type="email" name="email" value={values.email} onChange={handleChange} />
                                            {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                                        </div>

                                        <div className="col-md-12 mb-3 LogButtons text-center">
                                            <button className="btn login" type="submit"  >Send Request</button>
                                            <Link className="btn Register  mr-3" to="/">Log In</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </Fragment>
    )
}

export default Forgotpassword;