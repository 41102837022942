import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import AuthUser from './Auth/AuthUser';
import { CopyToClipboard } from "react-copy-to-clipboard";
const ThankyouPage = () => {
    const { http } = AuthUser();
    const [link, setLink] = useState("")
    const [Token, setToken] = useState("")
    const [isCopiedToken, setIsCopiedToken] = useState(false);

    const handleNextForm = () => {
        window.open(link, '_blank', 'noreferrer')
    }
    const location = useLocation();
    const pathID = location.state.split("&")[1];
    const onCopyToken = () => {
        setIsCopiedToken(true);
        setTimeout(() => {
            setIsCopiedToken(false);
        }, 1000);
    };

    useEffect(() => {

        if (pathID !== "") {

            http.get(`/userlinkdata/${pathID}/getLinkData`)
                .then((res) => {
                    // console.log(res)                 
                    setToken(res.data.data[0].audit_token)
                    setLink(location.state)

                })
                .catch(err => {
                    console.log(err)
                })


        }
    }, [location.state, pathID])

    return (
        <Fragment>
            <div className="vh-100 d-flex justify-content-center align-items-center mt-5 pt-5">
                <div>
                    <div className="mb-4 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-success" width={75} height={75} fill="currentColor" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </div>
                    <div className="text-center">
                        <h1>Thank You !</h1>
                        <p>For your valuable Feedback.</p>
                        <button className="btn btn-primary" onClick={handleNextForm}>Fill Another Form</button>
                        {/* <a href={link} target="_blank" className="btn btn-primary">{link}</a> */}
                        <p className="token">Token : {Token} <CopyToClipboard text={Token} onCopy={onCopyToken}>
                            <span className="copy-area">
                                {isCopiedToken ? <label >
                                    <i class="fa fa-check"></i></label> : <i className="fa fa-copy"></i>}
                            </span>
                        </CopyToClipboard></p>
                        <p>Copy the TOKEN above, then click on the above link to submit another form!</p>
                    </div>
                </div>
            </div>

        </Fragment >
    )
}

export default ThankyouPage;