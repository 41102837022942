import React, { Fragment } from "react";

const KeyNoteSpeech = ({ createAudit, errors, handleBackStep, handleSubmit, handleChange, handleUpload, handleRemovePdf }) => {

    return (
        <Fragment>
            <div className="col-md-3">
                {/* Tab navs */}
                <div className="nav flex-column nav-tabs text-center New-auditTabs">
                    <span className="nav-link">Create new audit</span>
                    <span className="nav-link active">Keynote speech</span>
                    <span className="nav-link">Use forms/templates</span>
                    <span className="nav-link">Generate link</span>
                    <span className="nav-link">Review &amp; Save</span>
                </div>
                {/* Tab navs */}
            </div>
            <div className="col-md-9">
                <div className="Audit-form-div">
                    {/* Tab content */}
                    <div className="tab-content">
                        {/* Start Keynote Speech */}
                        <div className="tab-pane active" id="v-tabs-speech" role="tabpanel">
                            <div className="audit-form">
                                <div className="audit-header">
                                    <h5 className="audit-title">Keynote Speech</h5>
                                </div>
                                <div className="audit-form-content">
                                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                                        <div className="row" style={{minHeight:297}}>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group file-group">
                                                        <label>Speech Details <small className="helper-text">(Please upload any PDF file)</small></label>
                                                        <input type="text" placeholder="Enter Speech details by uploading pdf" value={createAudit.audit_speech_detials || ''} className="form-control" readOnly />
                                                        <div className="input-group-btn">
                                                            <span className="fileUpload">
                                                                <img alt='Upload' src="/assets/images/upload.png" className="img-fluid" />
                                                                <input type="file" name="audit_pdf_file" className="upload up" id="up" onChange={handleUpload} accept="application/pdf" />
                                                                {createAudit.audit_speech_detials ? <span className="remove-btn" onClick={handleRemovePdf}>Remove PDF</span> : ""}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                {errors.audit_speech_detials && <p style={{ color: "red" }}>{errors.audit_speech_detials}</p>}
                                            </div>
                                        </div>

                                        {/* start footer buttons*/}
                                        <div className="col-md-12 px-0">
                                            <div className="text-right d-block audit-form-footer">
                                                <button type="button" className="mr-2 text-Bck btn" onClick={handleBackStep}>Back</button>
                                                <button type="submit" className="mr-2 text-Next btn">Save &amp; Next</button>
                                                {/* <button type="button" className="mr-2 text-Next btn" disabled>Save as Draft</button>
                                                <button type="button" className="text-Share btn" disabled>Share Audit</button> */}
                                            </div>
                                        </div>
                                        {/* End footer buttons*/}
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* End Keynote Speech */}
                    </div>
                    {/* Tab content */}
                </div>
            </div>

        </Fragment>
    )
}
export default KeyNoteSpeech;