import React, { Fragment, useEffect,useState } from 'react';
import Loader from './Auth/Loader';
import { useNavigate, Link, useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
    if (!localStorage.getItem('token')) {
      navigate('/', { replace: false });
    }
  }, [location]);
  const handleLogout = () => {
    // remove the token and user from the session storage
    setIsLoading(true);
    setTimeout(()=>{
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('user_id');
      navigate('/', { replace: false });
      setIsLoading(false);
    }, 1000);
    
}
  return (
    <Fragment>
      {isLoading ? <Loader /> : ""}
      <div id="left-sidebar" className="sidebar mini-sidebar">
        <div className="sidebar-scroll">

          <div className="tab-content p-l-0 p-r-0">
            <nav id="left-sidebar-nav" className="sidebar-nav">
              <ul id="main-menu" className="metismenu">
                <li className={(url === "/dashboard" ?" active" : "")}>
                  <Link to="/dashboard">
                    <img src={process.env.PUBLIC_URL + "/assets/images/dashboard.png"} alt="Dashboard-icon"></img>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className={(url === "/create-audit" ?" active" : "")}>
                  <Link to="/create-audit" >
                    <img src={process.env.PUBLIC_URL + "/assets/images/create-audit.png"} alt="Create-icon"></img>
                    <span>Create Audit</span>
                  </Link>
                </li>
                <li className={(url === "/templates" ?" active" : "")}>
                  <Link to="/templates">
                    <img src={process.env.PUBLIC_URL + "/assets/images/templates.png"} alt="Templates-icon"></img>
                    <span>Templates</span>
                  </Link>
                </li>
                <li className={(url === "/profile" ?" active" : "")}>
                  <Link to="/profile">
                    <img src={process.env.PUBLIC_URL + "/assets/images/profile-icon.png"} alt="Profile-icon"></img>
                    <span>Profile</span>
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLogout} to="">
                    <img src={process.env.PUBLIC_URL + "/assets/images/log-out.png"} alt="Log-icon"></img>
                    <span>Log Out</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Navbar;
