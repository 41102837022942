import React, { Fragment } from 'react';
function Header() {
  const handleSideBar = () => {
    document.body.classList.toggle('offcanvas-active')
  }
  return (
    <Fragment>

      <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-btn">
            <button type="button" className="btn-toggle-offcanvas" onClick={handleSideBar}>
              <i className="lnr lnr-menu fa fa-bars"></i>
            </button>
          </div>
          <div className="navbar-brand">
            <a href="/dashboard">
              <img src={process.env.PUBLIC_URL + "/assets/images/logo-bg.png"} alt="Logo" className="img-fluid logo"></img>
            </a>
          </div>
          {/* <div className="navbar-right">
            <div id="navbar-menu">
              <ul className="nav navbar-nav">
                <li className="dropdown">

                  <Link className="dropdown-toggle icon-menu" data-toggle="dropdown" to="/dashboard">
                    <img src={process.env.PUBLIC_URL + "/assets/images/notification.png"} alt="notification" className="img-fluid"></img>
                    <span className="notification-dot"></span>
                  </Link>
                  <ul className="dropdown-menu notifications">
                    <li className="header">
                      <strong>You have 4 new Notifications</strong>
                    </li>
                    <li>
                      <Link to="/dashboard">

                        <div className="media">
                          <div className="media-left">
                            <i className="icon-info text-warning"></i>
                          </div>
                          <div className="media-body">
                            <p className="text">Campaign <strong>Holiday Sale</strong> is nearly reach budget limit. </p>
                            <span className="timestamp">10:00 AM Today</span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashboard">
                        <div className="media">
                          <div className="media-left">
                            <i className="icon-like text-success"></i>
                          </div>
                          <div className="media-body">
                            <p className="text">Your New Campaign <strong>Holiday Sale</strong> is approved. </p>
                            <span className="timestamp">11:30 AM Today</span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashboard">
                        <div className="media">
                          <div className="media-left">
                            <i className="icon-pie-chart text-info"></i>
                          </div>
                          <div className="media-body">
                            <p className="text">Website visits from Twitter is 27% higher than last week.</p>
                            <span className="timestamp">04:00 PM Today</span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashboard">
                        <div className="media">
                          <div className="media-left">
                            <i className="icon-info text-danger"></i>
                          </div>
                          <div className="media-body">
                            <p className="text">Error on website analytics configurations</p>
                            <span className="timestamp">Yesterday</span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="footer">
                      <Link to="/dashboard" className="more">See all notifications</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/dashboard" className="icon-menu d-none d-sm-block">

                    <img src={process.env.PUBLIC_URL + "/assets/images/settings.png"} alt="settings" className="img-fluid "></img>
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </nav>

    </Fragment>
  );
}

export default Header;
