import React, { Fragment, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from './Header';
import Navbar from './Navbar';
import AuthUser from './Auth/AuthUser';
function Templates() {
  const navigate = useNavigate();
  const { token } = AuthUser();
  const handleSideBar = () => {
    document.body.classList.remove('offcanvas-active')
  }
  useEffect(() => {

    if (token === undefined || token === null) {

      navigate('/', { replace: false });
    }
  });
  return (
    <Fragment>
      <div id="wrapper">
        <Header />
        <div className="middlepart">
          <Navbar />
          <div id="main-content" onClick={handleSideBar}>
            <div className="container-fluid mb-5 NewAudit">
              <div className="Audicreate-div">
                <div className="row clearfix">
                  <div className="col-md-8">
                    <div className="audits-title">
                      <a href="#">All Templates</a>
                    </div>
                  </div>
                  {/* <div className="col-md-4 text-right">
                    <div className="Templates-search">
                      <img src="/assets/images/search-icon.png" alt="search" className="fa-search" />
                      <input type="text" className="form-control form-input" placeholder="Search by form name" />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="container-fluid mb-4">
              <div className="Audicreate-div">
                <div className="row clearfix">
                  <div className="col-lg-12 ">
                    <div className="audits-list">
                      <ul className="audits-items-list">

                        {/* <li className="status-holder templates-view">
                          <input type="radio" id="temp1" />
                          <label htmlFor="temp1">
                            <div className="audits-item ">
                              <div className="si-main-heading">
                                <div className="si-audits-title">
                                  <a href="javascript:void()">Template 1</a>
                                </div>
                              </div>
                              <ul className="si-metadata">
                                <li className="borderRight">
                                  <div className="si-metadata-genius">
                                    <div className="si-audits-title">
                                      <a href="javascript:void()">03</a>
                                      <p>Questions</p>
                                    </div>

                                  </div>
                                </li>
                              </ul>
                              <div className="si-main-heading">
                                <div className="si-audits-title">
                                  <a href="javascript:void()">--</a>
                                </div>
                                <div className="si-subtitle">
                                  <ul className="si-subtitle-items-list">
                                    <li>Estimated time
                                      to complete</li>
                                  </ul>
                                </div>
                              </div>
                              <ul className="si-metadata dwn-audit justify-content-end">
                                <li>
                                  <div className="si-metadata-genius">
                                    <a href="javascript:void()" className="btn btn-em templates-btn1" data-toggle="modal" data-target="#templates-Modal1">Preview</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </label>
                        </li> */}

                        {/* <li className="status-holder templates-view">
                          <input type="radio" id="temp2" />
                          <label htmlFor="temp2">
                            <div className="audits-item ">
                              <div className="si-main-heading">
                                <div className="si-audits-title">
                                  <a href="javascript:void()">Template 2</a>
                                </div>
                              </div>
                              <ul className="si-metadata">
                                <li className="borderRight">
                                  <div className="si-metadata-genius">
                                    <div className="si-audits-title">
                                      <a href="javascript:void()">03</a>
                                      <p>Questions</p>
                                    </div>

                                  </div>
                                </li>
                              </ul>
                              <div className="si-main-heading">
                                <div className="si-audits-title">
                                  <a href="javascript:void()">--</a>
                                </div>
                                <div className="si-subtitle">
                                  <ul className="si-subtitle-items-list">
                                    <li>Estimated time
                                      to complete</li>
                                  </ul>
                                </div>
                              </div>
                              <ul className="si-metadata dwn-audit justify-content-end">
                                <li>
                                  <div className="si-metadata-genius">
                                    <a href="javascript:void()" className="btn btn-em templates-btn2" data-toggle="modal" data-target="#templates-Modal2">Preview</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </label>
                        </li> */}

                        <li className="status-holder templates-view">
                          <input type="radio" id="temp3" />
                          <label htmlFor="temp3">
                            <div className="audits-item ">
                              <div className="si-main-heading">
                                <div className="si-audits-title">
                                  <a href="javascript:void()">Template 3</a>
                                </div>
                              </div>
                              <ul className="si-metadata">
                                <li className="borderRight">
                                  <div className="si-metadata-genius">
                                    <div className="si-audits-title">
                                      <a href="javascript:void()">03</a>
                                      <p>Questions</p>
                                    </div>

                                  </div>
                                </li>
                              </ul>
                              <div className="si-main-heading">
                                <div className="si-audits-title">
                                  <a href="javascript:void()">--</a>
                                </div>
                                <div className="si-subtitle">
                                  <ul className="si-subtitle-items-list">
                                    <li>Estimated time
                                      to complete</li>
                                  </ul>
                                </div>
                              </div>
                              <ul className="si-metadata dwn-audit justify-content-end">
                                <li>
                                  <div className="si-metadata-genius">
                                    <a href="javascript:void()" className="btn btn-em templates-btn3" data-toggle="modal" data-target="#templates-Modal3">Preview</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </label>
                        </li>
{/* 
                        <li className="status-holder templates-view">
                          <input type="radio" id="temp4" />
                          <label htmlFor="temp4">
                            <div className="audits-item ">
                              <div className="si-main-heading">
                                <div className="si-audits-title">
                                  <a href="javascript:void()">Template 4</a>
                                </div>
                              </div>
                              <ul className="si-metadata">
                                <li className="borderRight">
                                  <div className="si-metadata-genius">
                                    <div className="si-audits-title">
                                      <a href="javascript:void()">03</a>
                                      <p>Questions</p>
                                    </div>

                                  </div>
                                </li>
                              </ul>
                              <div className="si-main-heading">
                                <div className="si-audits-title">
                                  <a href="javascript:void()">--</a>
                                </div>
                                <div className="si-subtitle">
                                  <ul className="si-subtitle-items-list">
                                    <li>Estimated time
                                      to complete</li>
                                  </ul>
                                </div>
                              </div>
                              <ul className="si-metadata dwn-audit justify-content-end">
                                <li>
                                  <div className="si-metadata-genius">
                                    <a href="javascript:void()" className="btn btn-em templates-btn4" data-toggle="modal" data-target="#templates-Modal4">Preview</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </label>
                        </li> */}

                        {/* <li className="status-holder templates-view">
                          <input type="radio" id="temp5" />
                          <label htmlFor="temp5">
                            <div className="audits-item ">
                              <div className="si-main-heading">
                                <div className="si-audits-title">
                                  <a href="javascript:void()">Template 5</a>
                                </div>
                              </div>
                              <ul className="si-metadata">
                                <li className="borderRight">
                                  <div className="si-metadata-genius">
                                    <div className="si-audits-title">
                                      <a href="javascript:void()">03</a>
                                      <p>Questions</p>
                                    </div>

                                  </div>
                                </li>
                              </ul>
                              <div className="si-main-heading">
                                <div className="si-audits-title">
                                  <a href="javascript:void()">--</a>
                                </div>
                                <div className="si-subtitle">
                                  <ul className="si-subtitle-items-list">
                                    <li>Estimated time
                                      to complete</li>
                                  </ul>
                                </div>
                              </div>
                              <ul className="si-metadata dwn-audit justify-content-end">
                                <li>
                                  <div className="si-metadata-genius">
                                    <a href="javascript:void()" className="btn btn-em templates-btn5" data-toggle="modal" data-target="#templates-Modal5">Preview</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </label>
                        </li> */}

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* template 1 */}
            <div className="modal fade templatesModal" id="templates-Modal1" tabIndex="-1" role="dialog">
              <div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="defaultModalLabel">Form - 1</h4>
                    <button type="button" className="close" data-dismiss="modal">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row clearfix">
                      <div className="col-12">
                        <h4>Team Action Management</h4>
                        <p>Delivers Successful Change & Increase The Performance To All People-Based Organizations</p>
                        <div className="breadcrumb vt-a">
                          <a href="javascript:void(0);"> Form 1: Your Views/Opinions</a>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Please give a short title </label>
                          <span>
                            <input type="text" className="form-control" placeholder="Title" />
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Statement of your view or opinion - 1 per form please -</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Do you want to give an example?</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer text-right">
                    <a href="./form-view1.html" className=" btn btn-primary">Submit</a>
                  </div>
                  <div className="footer-bottom">
                    <p className="footer-text">Unleashing the power</p>
                    <p> TAM Uk © 2023, Team Action Management </p>
                  </div>
                </div>
              </div>
            </div>
            {/* template 2 */}
            <div className="modal fade templatesModal" id="templates-Modal2" tabIndex="-1" role="dialog">
              <div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="defaultModalLabel">Form - 2</h4>
                    <button type="button" className="close" data-dismiss="modal">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row clearfix">
                      <div className="col-12">
                        <h4>Team Action Management</h4>
                        <p>Delivers Successful Change & Increase The Performance To All People-Based Organizations</p>
                        <div className="breadcrumb vt-a">
                          <a href="javascript:void(0);"> Form 2: Your Views/Opinions</a>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Please give a short title </label>
                          <span>
                            <input type="text" className="form-control" placeholder="Title" />
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="same_field_box red-border">
                          <label>Describe which box your title refers to & tick </label>
                          <div className="row mt-2">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <h6>PRESENT</h6>
                              <div className="custom-radio">
                                <input type="radio" name="diseaseans" id="Strength" />
                                <label htmlFor="Strength">Strength</label>
                              </div>
                              <div className="custom-radio">
                                <input type="radio" name="diseaseans" id="Fault" />
                                <label htmlFor="Fault">Fault</label>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <h6>FUTURE</h6>
                              <div className="custom-radio">
                                <input type="radio" name="diseaseans" id="Oppurtunity" />
                                <label htmlFor="Oppurtunity">Oppurtunity</label>
                              </div>
                              <div className="custom-radio">
                                <input type="radio" name="diseaseans" id="Threat" />
                                <label htmlFor="Threat">Threat</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Statement of your view or opinion - 1 per form please -</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer text-right">
                    <a href="./form-view2.html" className=" btn btn-primary">Submit</a>
                  </div>
                  <div className="footer-bottom">
                    <p className="footer-text">Unleashing the power</p>
                    <p> TAM Uk © 2023, Team Action Management </p>
                  </div>
                </div>
              </div>
            </div>
            {/* template 3 */}
            <div className="modal fade templatesModal" id="templates-Modal3" tabIndex="-1" role="dialog">
              <div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="defaultModalLabel">Template - 3</h4>
                    <button type="button" className="close" data-dismiss="modal">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row clearfix">
                      <div className="col-12">
                        <h4>Team Action Management</h4>
                        <p>Delivers Successful Change & Increase The Performance To All People-Based Organizations</p>
                        <div className="breadcrumb vt-a">
                          <a href="javascript:void(0);"> Template 3: Your Views/Opinions</a>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Please give a short title </label>
                          <span>
                            <input type="text" className="form-control" placeholder="Title" />
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="same_field_box red-border">
                          <label>Describe which box your title refers to & tick </label>
                          <div className="row mt-2">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <h6>PRESENT</h6>
                              <div className="custom-radio">
                                <input type="radio" name="diseaseans" id="Strength3" />
                                <label htmlFor="Strength3">Strength</label>
                              </div>
                              <div className="custom-radio">
                                <input type="radio" name="diseaseans" id="Fault3" />
                                <label htmlFor="Fault3">Fault</label>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <h6>FUTURE</h6>
                              <div className="custom-radio">
                                <input type="radio" name="diseaseans" id="Oppurtunity3" />
                                <label htmlFor="Oppurtunity3">Oppurtunity</label>
                              </div>
                              <div className="custom-radio">
                                <input type="radio" name="diseaseans" id="Threat3" />
                                <label htmlFor="Threat3">Threat</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Statement of your view or opinion - 1 per form please -</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Do you want to give an example?</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>What could be done -</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer text-right">
                    <a href="#" className=" btn btn-primary">Submit</a>
                  </div>
                  <div className="footer-bottom">
                    <p className="footer-text">Unleashing the power</p>
                    <p> TAM Uk © 2023, Team Action Management </p>
                  </div>
                </div>
              </div>
            </div>
            {/* template 4 */}
            <div className="modal fade templatesModal" id="templates-Modal4" tabIndex="-1" role="dialog">
              <div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="defaultModalLabel">Form - 4</h4>
                    <button type="button" className="close" data-dismiss="modal">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row clearfix">
                      <div className="col-12">
                        <h4>Team Action Management</h4>
                        <p>Delivers Successful Change & Increase The Performance To All People-Based Organizations</p>
                        <div className="breadcrumb vt-a">
                          <a href="javascript:void(0);"> Form 2: Your Views/Opinions</a>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Please give a short title </label>
                          <span>
                            <input type="text" className="form-control" placeholder="Title" />
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Statement of your view or opinion - 1 per form please -</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Do you want to give an example?</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>What could be done -</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer text-right">
                    <a href="./form-view4.html" className=" btn btn-primary">Submit</a>
                  </div>
                  <div className="footer-bottom">
                    <p className="footer-text">Unleashing the power</p>
                    <p> TAM Uk © 2023, Team Action Management </p>
                  </div>
                </div>
              </div>
            </div>
            {/* template 5 */}
            <div className="modal fade templatesModal" id="templates-Modal5" tabIndex="-1" role="dialog">
              <div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="defaultModalLabel">Form - 5</h4>
                    <button type="button" className="close" data-dismiss="modal">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row clearfix">
                      <div className="col-12">
                        <h4>Team Action Management</h4>
                        <p>Delivers Successful Change & Increase The Performance To All People-Based Organizations</p>
                        <div className="breadcrumb vt-a">
                          <a href="javascript:void(0);"> Form 2: Your Views/Opinions</a>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Please give a short title </label>
                          <span>
                            <input type="text" className="form-control" placeholder="Title" />
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Statement of your view or opinion - 1 per form please -</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>Do you want to give an example?</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <label>What could be done -</label>
                        <div className="text_field_box spacing-top">
                          <textarea placeholder="Enter text"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer text-right">
                    <a href="./form-view5.html" className=" btn btn-primary">Submit</a>
                  </div>
                  <div className="footer-bottom">
                    <p className="footer-text">Unleashing the power</p>
                    <p> TAM Uk © 2023, Team Action Management </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Templates;
