import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";

import { Modal, Button } from 'react-bootstrap';
import Header from './Header';
import Navbar from './Navbar';
import AuthUser from './Auth/AuthUser';
import { CopyToClipboard } from "react-copy-to-clipboard";

import ReactToPrint from 'react-to-print';


function AuditDetails() {
    const singlePDFRef = useRef(null);
    const multiPDFRef = useRef(null);

    const navigate = useNavigate();
    const { token } = AuthUser();
    const { http } = AuthUser();
    let { id } = useParams();
    const [auditName, setAuditName] = useState("");
    // const [auditDesc, setAuditDesc] = useState("");
    const [auditStatus, setAuditStatus] = useState("");
    const [auditSpeechDetials, setAuditSpeechDetials] = useState("");
    const [audit_pdf, setAuditPdf] = useState("");
    const [createdAt, setCreatedAt] = useState("");
    const [expireAt, setExpireAt] = useState("");
    const [auditLogo, setAuditLogo] = useState("");
    // const [templateName, setTemplateName] = useState("");
    const [templateLink, setTemplateLink] = useState("");
    const [auditToken, setAuditToken] = useState("");
    const [templateID, setTemplateID] = useState(0);


    const [userList, setUserList] = useState({})
    const [link_id, setLink_id] = useState("")
    const [modalData, setModalData] = useState("")
    const [csvData, setCsvData] = useState("")
    const csvLink = useRef()

    // Modal Window States
    const [show, setShow] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [isCopiedToken, setIsCopiedToken] = useState(false);

    const [showAllRecord, setShowAllRecord] = useState(false);


    const handleClose = () => {
        setShow(false);
        setShowAllRecord(false);
    }

    const handleSideBar = () => {
        document.body.classList.remove('offcanvas-active')
    }
    const onCopyLink = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };
    const onCopyToken = () => {
        setIsCopiedToken(true);
        setTimeout(() => {
            setIsCopiedToken(false);
        }, 1000);
    };

    const handleShow = (id) => {
        let userData = userList.filter((item) => item.id === id);
        setModalData(userData[0]);
        setShow(true);
    }

    const downloadAllPdf = () => {
        setShowAllRecord(true)
    }
    const bgColor = {
        backgroundColor: '#f4f6f9'
    }
    const header = {
        backgroundColor: '#faeecf',
        width: '800px',
        padding: '12px 15px',
        display: 'inline-block'
    }

    const checkBg = {
        backgroundColor: '#faeecf',
        border: '1px solid #faeecf',
        position: "absolute",
        top: 3,
        left: 0,
        width: 14,
        height: 14,
        zIndex: 2
    }
    if (templateID === 1) {
        header.backgroundColor = '#faeecf'
        checkBg.backgroundColor = '#ffb703'
        checkBg.borderColor = '#ffb703'
    }
    if (templateID === 2) {
        header.backgroundColor = '#d2e8e7'
        checkBg.backgroundColor = '#2a9c8'
        checkBg.borderColor = '#2a9c8'
    }

    if (templateID === 3) {
        header.backgroundColor = '#c9e2f6'
        checkBg.backgroundColor = '#0983dc'
        checkBg.borderColor = '#0983dc'
    }
    if (templateID === 4) {
        header.backgroundColor = '#f4e0dd'
        checkBg.backgroundColor = '#df7a5f'
        checkBg.borderColor = '#df7a5f'
    }
    if (templateID === 5) {
        header.backgroundColor = '#e7ded9'
        checkBg.backgroundColor = '#9b6644'
        checkBg.borderColor = '#9b6644'
    }

    const headers = [
        { label: "Users", key: "id" },
        { label: "Title", key: "title" },
        { label: "Present", key: "refers_to" },
        { label: "Statement of your view or opinion", key: "statement_view" },
        { label: "You have give an example", key: "example" },
        { label: "You have be done ", key: "done" }

    ];
    const getFileName = (link_id) => {


        return "Response_" + link_id + ".csv";
    }
    const downloadReport = async (link_id) => {
        if (link_id) {
            await http.get(`/linkuser/${link_id}`)
                .then(res => {

                    setCsvData(res.data.data)
                })
                .catch(err => {
                    console.log(err)
                })
            setTimeout(() => {
                csvLink.current.link.click()
            });

        }
    }

    const goBack = () => {
        navigate(-1)
    }

    useEffect(() => {

        if (token === undefined || token === null) {

            navigate('/', { replace: false });
        }
        if (id && auditName === "") {
            http.get(`/useraudit/${id}`)
                .then(res => {
                    setAuditName(res.data.data.audit_name);
                    // setAuditDesc(res.data.data.audit_description);
                    setAuditStatus(res.data.data.status);
                    setAuditSpeechDetials(res.data.data.audit_speech_detials);
                    setAuditPdf(res.data.data.audit_pdf);
                    setCreatedAt(res.data.data.created_at);
                    setExpireAt(res.data.data.expired_at);
                    setAuditLogo(res.data.data.audit_logo.replace("public", ""));
                    // ${auditLogo.replace("public", "")
                    // setTemplateName(res.data.data.template_name);
                    setTemplateLink(res.data.data.template_link);
                    setTemplateID(res.data.data.template_id);
                    setAuditToken(res.data.data.audit_token);
                    setLink_id(res.data.data.template_link.split("&")[1])
                })
                .catch(err => {
                    console.log(err)
                })
        }

        if (link_id && !userList.length) {
            http.get(`/linkuser/${link_id}`)
                .then(res => {
                    setUserList(res.data.data);
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [link_id]);
    return (
        <Fragment>
            <div id="wrapper">
                <Header />
                <div className="middlepart">
                    <Navbar />
                    <div id="main-content" onClick={handleSideBar}>
                        <div className="container-fluid mb-5 NewAudit">
                            <div className="Audicreate-div">
                                <div className="row clearfix">
                                    <div className="col-lg-6">
                                        <div className="audits-title">

                                            <h6>{auditName}</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 text-right">
                                        {/* <div className="audits-form-title">
                    <a href="#">Form 1</a>
                  </div> */}
                                        <div className="sm-audits-summary__card-title">Created on {createdAt} <span className="wds-p-l-2 wds-p-r-2">|</span>End: {expireAt}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* start active section  */}
                        <div className="container-fluid mb-4">
                            <div className="Audicreate-div ">
                                <div className="row clearfix">
                                    <div className="col-lg-6">
                                        <div className="audits-title">
                                            <h6> <i className="fa fa-arrow-left mr-2 cursor-pointer" onClick={goBack} /> Overall Audit Details</h6>
                                        </div>
                                    </div>
                                    <div className={`col-lg-6 text-right ${auditStatus === "expired" || auditStatus === "Expired" ? "d-none" : ""}`}>
                                        <ul className="sm-response-data__navbar">
                                            <li className="sm-response-data__navbar--item">
                                                <a href={'/create-audit/' + id} className="wds-link">Edit Audit</a>
                                            </li>

                                            {/* <li className={`sm-response-data__navbar--item  ${auditStatus === "active" || auditStatus === "Active" ? "" : "d-none"}`}>
                                                <a href="#" className="wds-link">Move to drafts</a>
                                            </li>
                                            <li className={`sm-response-data__navbar--item  ${auditStatus === "active" || auditStatus === "Active" ? "" : "d-none"}`}>
                                                <a href="#" className="wds-link">Close Audit</a>
                                            </li>
                                            <li className={`sm-response-data__navbar--item  ${auditStatus === "active" || auditStatus === "Active" ? "" : "d-none"}`}>
                                                <a href="#" className="wds-link">Share Audit</a>
                                            </li>
                                            <li className={`sm-response-data__navbar--item  ${auditStatus === "draft" || auditStatus === "Draft" ? "" : "d-none"}`}>
                                                <a href="#" className="wds-link">Publish Audit</a>
                                            </li>
                                            <li className={`sm-response-data__navbar--item  ${auditStatus === "draft" || auditStatus === "Draft" ? "" : "d-none"}`}>
                                                <a href="#" className="wds-link">Delete Audit</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-lg-12 ">
                                        <div className="audits-list">
                                            <ul className="audits-items-list">
                                                <li className="status-holder">
                                                    <div className="audits-item auditlist-spaceing">
                                                        <ul className="si-metadata">
                                                            <li>
                                                                <div className="si-metadata-genius">
                                                                    <span className={`si-status ${(auditStatus === "Active" || auditStatus === "active") ? "audits-status-active" : ""} 
                                                                ${(auditStatus === "future" || auditStatus === "Future") ? "audits-status-future" : ""}
                                                                ${(auditStatus === "expired" || auditStatus === "Expired") ? "audits-status-closed" : ""}${(auditStatus === "draft" || auditStatus === "Draft") ? "audits-status-draft" : ""}`}>{auditStatus}</span>

                                                                    <p>Overall Audit Status</p>
                                                                </div>
                                                            </li>
                                                            <li className='border-0'>
                                                                <div className="si-metadata-genius">
                                                                    <span className="si-token-large">{userList && userList.length ? userList.length : 0}</span> Collectors
                                                                </div>
                                                            </li>
                                                            <li className='border-0'>
                                                                <div className="si-metadata-genius invisible">
                                                                    <span className="si-token-large">12</span> Total No.
                                                                    Questions
                                                                </div>
                                                            </li>
                                                            <li className='border-0'>
                                                                <div className="si-metadata-genius invisible">
                                                                    <span className="si-token-large">10 mins</span> Estimated time <br /> to complete
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="si-metadata-genius invisible">
                                                                    <span className="si-token-large"><img alt='Analysis Icon' src="/assets/images/data-analysis-icon.png" className="img-fluid " /></span> Analyze Report
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Start  Audit Links section */}
                        <div className={`container-fluid mb-4 ${(auditStatus === "expired" || auditStatus === "Expired") ? "d-none" : ""}`}>
                            <div className="Audicreate-div">
                                <div className="row clearfix">
                                    <div className="col-lg-12">
                                        <div className="audits-title">
                                            <p className='row align-items-baseline'><span className='col-auto pr-0'>Audit Link -</span>
                                                <span className='col-10 pl-1 pr-0'> <span className="copy-area-con"><a href={templateLink} target="_blank" rel="noreferrer">{templateLink}</a><CopyToClipboard text={templateLink} onCopy={onCopyLink}>
                                                    <span className="copy-area">
                                                        {isCopied ? <label >
                                                            <i className="fa fa-check"></i> </label> : <i className="fa fa-copy"></i>}
                                                    </span>
                                                </CopyToClipboard></span>
                                                </span>
                                            </p>
                                            <p className='row align-items-baseline'><span className='col-auto pr-0'>Audit Token I'D - </span> <a className="token" href="#"> {auditToken}</a>
                                                <CopyToClipboard text={auditToken} onCopy={onCopyToken}>
                                                    <span className="copy-area">
                                                        {isCopiedToken ? <label >
                                                            <i className="fa fa-check"></i></label> : <i className="fa fa-copy"></i>}
                                                    </span>
                                                </CopyToClipboard></p>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-2 text-right">
                                        <a href="#" className="btn btn-em">Edit Audit Link</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* Start  Response  Collected section */}
                        <div className="container-fluid mb-4">
                            <div className="Audicreate-div">
                                <div className="row clearfix borderBottom">
                                    <div className="col-lg-3">
                                        <div className="audits-title">
                                            <h6>Responses Collected</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-9 text-right">
                                        <input type="button" className="btn btn-em" onClick={() => downloadReport(link_id)} value="Export all Responses as CSV Format" disabled={userList.length ? null : "disabled"} />
                                        <CSVLink
                                            headers={headers}
                                            filename={getFileName(link_id)}
                                            data={csvData}
                                            ref={csvLink}
                                        />
                                        <input type="button" className="btn btn-em ml-2" onClick={() => downloadAllPdf(link_id)} value="Export all Responses as PDF Format" disabled={userList.length ? null : "disabled"} />
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-lg-12 ">
                                        <div className="audits-list">
                                            <ul className="audits-items-list resposeDiv">
                                                {
                                                    userList && userList.length ? userList.map((item) => {
                                                        return <li key={item.id} className="status-holder">
                                                            <div className="audits-item ">
                                                                <div className="si-main-heading">
                                                                    <div className="si-audits-title">
                                                                        <a href="#">User {item.id}</a>
                                                                    </div>
                                                                    <div className="si-subtitle">
                                                                        <ul className="si-subtitle-items-list">
                                                                            <li>Form Filled On: {item.created_at}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <ul className="si-metadata">
                                                                    <li className="borderRight">
                                                                        <div className="si-metadata-genius invisible">
                                                                            <div className="si-audits-title">
                                                                                <a href="#">20/20</a>
                                                                                <p>Questions Filled</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <div className="si-main-heading invisible">
                                                                    <div className="si-audits-title">
                                                                        <a href="#">5mins</a>
                                                                    </div>
                                                                    <div className="si-subtitle">
                                                                        <ul className="si-subtitle-items-list">
                                                                            <li>Estimated time to complete</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <ul className="si-metadata dwn-audit">
                                                                    <li>
                                                                        <div className="si-metadata-genius w-auto">
                                                                            <a href="#" className="btn report-btn" onClick={() => handleShow(item.id)}>View &amp; Download Submitted Form</a>
                                                                        </div>
                                                                    </li>
                                                                    {/* <li>
                                                                        <div className="si-metadata-genius" onClick={(e) => printDocument(e, item.id)}>
                                                                            <a href="#" className="btn report-btn" onClick={(e) => printDocument(e, item.id)}>Save as PDF</a>
                                                                        </div>
                                                                    </li> */}
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    }) : (auditStatus === "Draft" || auditStatus === "draft") ? <div className="row clearfix">
                                                        <div className="col-lg-12 ">
                                                            <div className="audits-list">
                                                                <div className="Nodata-found">
                                                                    <h2>No Response Collected</h2>
                                                                    <a href="javascript:void()" className="btn btn-publish">Publish Audit</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : <div className="row clearfix">
                                                        <div className="col-lg-12 ">
                                                            <div className="audits-list">
                                                                <div className="Nodata-found">
                                                                    <h2>No Response Collected</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for save pdf for specifix Record */}
            <Modal

                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="templatesModal pdfDocument"
                // size="lg"
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h4>View/Download Submitted Form</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div ref={singlePDFRef} style={{ paddingTop: 10 }}>
                        <table id={`templates-Modal${templateID}`} className={`form-view form-${templateID}`} cellPadding={0} cellSpacing={0} border={0} width={800} style={{ minWidth: '700px', margin: '0 auto', backgroundColor: '#fff', fontFamily: '"Roboto",sans-serif' }}>
                            <tbody>
                                <tr>
                                    <td style={bgColor}>
                                        <table cellPadding={0} cellSpacing={0} border={0} width={700} style={{ minWidth: '700px', margin: '0 auto', backgroundColor: '#000', fontFamily: '"Roboto",sans-serif' }}>
                                            <tbody><tr>
                                                <td width={800} style={bgColor}>
                                                    <div style={header}>
                                                        <div style={{ width: '50%', display: "inline-block" }}>
                                                            <img style={{ maxWidth: '204px', maxHeight: 100 }} alt="logo" src={auditLogo !== "null" ? `https://api.teamactionmanagement.com/storage${auditLogo}` : "/assets/images/pwrd-logo.png"} />
                                                        </div>
                                                        <div style={{ width: '50%', display: "inline-block", textAlign: 'right' }}>
                                                            <img src="/assets/images/pwrd-logo.png" style={{ maxWidth: '204px', maxHeight: 100 }} alt="logo" />
                                                        </div>
                                                    </div>
                                                    <form>
                                                        <div className="row" style={{ margin: 0, padding: '15px' }}>
                                                            <div className="col-12 px-0">
                                                                <h4>Team Action Management</h4>
                                                                <p>Delivers Successful Change &amp; Increase The Performance To All People-Based Organizations</p>
                                                                <p>{auditSpeechDetials} <a className="ml-5 text-underline" href={`https://api.teamactionmanagement.com/storage${audit_pdf.replace("public", "")}`} target="_blank" rel="noreferrer">View Keynote Speech</a></p>
                                                                <div className="breadcrumb vt-a">
                                                                    <a href="#"> Template {templateID}: Your Views/Opinions</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 px-0">
                                                                <div className="form-group">
                                                                    <label>Please give a short title </label>
                                                                    <span>
                                                                        <input type="text" className="form-control" placeholder="Title" name="title" defaultValue={modalData.title || ''} readOnly style={{ backgroundColor: "#fff" }} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 px-0">
                                                                <div className="same_field_box red-border">
                                                                    <label>Describe which box your title refers to &amp; tick </label>
                                                                    <div className="row mt-2">
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                            <h6>Present</h6>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="refers_to" id="Strength" defaultValue="Strength"
                                                                                />
                                                                                <label>
                                                                                    {modalData.refers_to === "Strength" ?
                                                                                        <span style={checkBg}><img src='/assets/images/checkBox-tick.png' alt='Tick' style={{ width: 10, height: 10, position: "absolute", top: 1, left: 1, zIndex: 2 }} />
                                                                                        </span> : ""}
                                                                                    Strength
                                                                                </label>
                                                                            </div>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="refers_to" id="Fault" defaultValue="Fault" />
                                                                                <label>
                                                                                    {modalData.refers_to === "Fault" ?
                                                                                        <span style={checkBg}><img src='/assets/images/checkBox-tick.png' alt='Tick' style={{ width: 10, height: 10, position: "absolute", top: 1, left: 1, zIndex: 2 }} />
                                                                                        </span> : ""}
                                                                                    Fault
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                            <h6>Future</h6>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="refers_to" id="Opportunity" defaultValue="Opportunity" />
                                                                                <label className={`${modalData.refers_to === "Opportunity" ? "active-CheckBox" : ""}`}>
                                                                                    {modalData.refers_to === "Opportunity" ?
                                                                                        <span style={checkBg}><img src='/assets/images/checkBox-tick.png' alt='Tick' style={{ width: 10, height: 10, position: "absolute", top: 1, left: 1, zIndex: 2 }} />
                                                                                        </span> : ""}
                                                                                    Opportunity
                                                                                </label>
                                                                            </div>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" name="refers_to" id="Threat" defaultValue="Threat" />
                                                                                <label>
                                                                                    {modalData.refers_to === "Threat" ?
                                                                                        <span style={checkBg}><img src='/assets/images/checkBox-tick.png' alt='Tick' style={{ width: 10, height: 10, position: "absolute", top: 1, left: 1, zIndex: 2 }} />
                                                                                        </span> : ""}
                                                                                    Threat
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 px-0">
                                                                <label>Statement of your view or opinion - 1 per form please -</label>
                                                                <div className="text_field_box spacing-top">
                                                                    {/* <textarea placeholder="Enter text" name="statement_view" defaultValue={modalData.statement_view || ''} readOnly /> */}
                                                                    <span className={modalData.statement_view ? '':'waterMark-text'}>{modalData.statement_view || '\u00A0'}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 px-0">
                                                                <label>Do you want to give an example?</label>
                                                                <div className="text_field_box spacing-top">
                                                                    {/* <textarea placeholder="Enter text" name="example" defaultValue={modalData.example || ''} readOnly /> */}
                                                                    <span className={modalData.example ? '':'waterMark-text'}>{modalData.example || '\u00A0'}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 px-0">
                                                                <label>What could be done -</label>
                                                                <div className="text_field_box spacing-top">
                                                                    {/* <textarea placeholder="Enter text" name="done" defaultValue={modalData.done || ''} readOnly /> */}
                                                                    <span className={modalData.done ? '':'waterMark-text'}>{modalData.done || '\u00A0'}</span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <p style={{ background: "#eff0f1", borderBottom: "1px solid #cacbcc", padding: "15px 15px 11px 15px", margin: "0" }}>Unleashing the power</p>
                                                    <p style={{ background: "#eff0f1", padding: "13px 15px 11px 15px", color: "#34373c", fontSize: "13px", float: "left", width: "100%", margin: "0" }}>  <span style={{ display: 'inline-block', width: "auto" }}>TAM Uk © 2023, Team Action Management</span> <span style={{ float: "right", width: "auto" }}>To Re-Order _______ Form No. 1001</span></p>

                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <ReactToPrint
                        content={() => singlePDFRef.current}
                        trigger={() => <button className="btn btn-primary">Download</button>}
                    />
                </Modal.Footer>
            </Modal>

            {/* Modal for save pdf for All Records */}
            <Modal

                show={showAllRecord}
                scrollable={true}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="templatesModal pdfDocument"
                // size="lg"
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h4>View/Download Submitted Form</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center pb-0'>
                    <div ref={multiPDFRef} id={`templates-Modal${templateID}`} style={{ width: '800px', margin: '0 auto', backgroundColor: '#fff', fontFamily: '"Roboto",sans-serif', display: 'block', textAlign: "left" }}>
                        {
                            userList && userList.length ? userList.map((item, index) => {
                                return (
                                    <div key={index} style={{ minHeight: 1028, paddingTop: 10 }}>
                                        <table className={`form-view form-${templateID}`} cellPadding={0} cellSpacing={0} border={0} width={800} >
                                            <tbody>
                                                <tr>
                                                    <td style={bgColor}>
                                                        <table cellPadding={0} cellSpacing={0} border={0} width={700} style={{ minWidth: '700px', margin: '0 auto', backgroundColor: '#000', fontFamily: '"Roboto",sans-serif' }}>
                                                            <tbody><tr>
                                                                <td width={800} style={bgColor}>
                                                                    <div style={header}>
                                                                        <div style={{ width: '50%', display: "inline-block" }}>
                                                                            <img src={auditLogo ? `https://api.teamactionmanagement.com/storage${auditLogo}` : "/assets/images/pwrd-logo.png"} style={{ maxWidth: '204px', maxHeight: 100 }} alt="logo" />
                                                                        </div>
                                                                        <div style={{ width: '50%', display: "inline-block", textAlign: 'right' }}>
                                                                            <img src="/assets/images/pwrd-logo.png" style={{ maxWidth: '204px', maxHeight: 100 }} alt="logo" />
                                                                        </div>
                                                                    </div>
                                                                    <form>
                                                                        <div className="row" style={{ margin: 0, padding: '15px' }}>
                                                                            <div className="col-12 px-0">
                                                                                <h4>Team Action Management</h4>
                                                                                <p>Delivers Successful Change &amp; Increase The Performance To All People-Based Organizations</p>
                                                                                <div className="breadcrumb vt-a">
                                                                                    <a href="#"> Template {templateID}: Your Views/Opinions</a>
                                                                                </div>
                                                                                <h4>Keynote Speech</h4>
                                                                                <p>{auditSpeechDetials} <a className="ml-5" href={`https://api.teamactionmanagement.com/storage${audit_pdf.replace("public", "")}`} target="_blank" rel="noreferrer">View Keynote Speech</a></p>
                                                                            </div>
                                                                            <div className="col-12 px-0">
                                                                                <div className="form-group">
                                                                                    <label>Please give a short title </label>
                                                                                    <span>
                                                                                        <input type="text" className="form-control" placeholder="Title" name="title" defaultValue={item.title || ''} readOnly style={{ backgroundColor: "#fff" }} />
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 px-0">
                                                                                <div className="same_field_box red-border">
                                                                                    <label>Describe which box your title refers to &amp; tick </label>
                                                                                    <div className="row mt-2">
                                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                            <h6>Present</h6>
                                                                                            <div className="custom-radio">
                                                                                                <input type="radio" name="refers_to" id="Strength" defaultValue="Strength"
                                                                                                />
                                                                                                <label>
                                                                                                    {item.refers_to === "Strength" ?
                                                                                                        <span style={checkBg}><img src='/assets/images/checkBox-tick.png' alt='Tick' style={{ width: 10, height: 10, position: "absolute", top: 1, left: 1, zIndex: 2 }} />
                                                                                                        </span> : ""}
                                                                                                    Strength
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="custom-radio">
                                                                                                <input type="radio" name="refers_to" id="Fault" defaultValue="Fault" />
                                                                                                <label>
                                                                                                    {item.refers_to === "Fault" ?
                                                                                                        <span style={checkBg}><img src='/assets/images/checkBox-tick.png' alt='Tick' style={{ width: 10, height: 10, position: "absolute", top: 1, left: 1, zIndex: 2 }} />
                                                                                                        </span> : ""}
                                                                                                    Fault
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                            <h6>Future</h6>
                                                                                            <div className="custom-radio">
                                                                                                <input type="radio" name="refers_to" id="Opportunity" defaultValue="Opportunity" />
                                                                                                <label>
                                                                                                    {item.refers_to === "Opportunity" ?
                                                                                                        <span style={checkBg}><img src='/assets/images/checkBox-tick.png' alt='Tick' style={{ width: 10, height: 10, position: "absolute", top: 1, left: 1, zIndex: 2 }} />
                                                                                                        </span> : ""}
                                                                                                    Opportunity
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="custom-radio">
                                                                                                <input type="radio" name="refers_to" id="Threat" defaultValue="Threat" />
                                                                                                <label>
                                                                                                    {item.refers_to === "Threat" ?
                                                                                                        <span style={checkBg}><img src='/assets/images/checkBox-tick.png' alt='Tick' style={{ width: 10, height: 10, position: "absolute", top: 1, left: 1, zIndex: 2 }} />
                                                                                                        </span> : ""}
                                                                                                    Threat
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 px-0">
                                                                                <label>Statement of your view or opinion - 1 per form please -</label>
                                                                                <div className="text_field_box spacing-top">
                                                                                    {/* <textarea placeholder="Enter text" name="statement_view" defaultValue={item.statement_view || ''} readOnly /> */}
                                                                                    <span className={item.statement_view ? '':'waterMark-text'}>{item.statement_view || '\u00A0'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 px-0">
                                                                                <label>Do you want to give an example?</label>
                                                                                <div className="text_field_box spacing-top">
                                                                                    {/* <textarea placeholder="Enter text" name="example" defaultValue={item.example || ''} readOnly /> */}
                                                                                    <span className={item.example ? '':'waterMark-text'}>{item.example || '\u00A0'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 px-0">
                                                                                <label>What could be done -</label>
                                                                                <div className="text_field_box spacing-top">
                                                                                    {/* <textarea placeholder="Enter text" name="done" defaultValue={item.done || ''} readOnly /> */}
                                                                                    <span className={item.done ? '':'waterMark-text'}>{item.done || '\u00A0'}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                    <p style={{ background: "#eff0f1", borderBottom: "1px solid #cacbcc", padding: "15px 15px 11px 15px", margin: "0" }}>Unleashing the power</p>
                                                                    <p style={{ background: "#eff0f1", padding: "13px 15px 11px 15px", color: "#34373c", fontSize: "13px", float: "left", width: "100%", margin: "0" }}>  <span style={{ display: 'inline-block', width: "auto" }}>TAM Uk © 2023, Team Action Management</span> <span style={{ float: "right", width: "auto" }}>To Re-Order _______ Form No. 1001</span></p>

                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }) : ""
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    {/* <Button variant="primary" onClick={handleDownloadPdf}>Download</Button> */}
                    <ReactToPrint
                        content={() => multiPDFRef.current}
                        trigger={() => <button className="btn btn-primary">Download</button>}
                    />
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default AuditDetails;
