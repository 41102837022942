import React, { Fragment, useState, useEffect } from 'react';
import validation from './ResetValidation';
import './SideBar';
import { Link, useNavigate, useLocation } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from './AuthUser';
import SideBar from './SideBar';
function Resetpassword() {

    const navigate = useNavigate();
    const { http } = AuthUser();
    const [errorMessage] = useState('');

    const location = useLocation();

    const token = location.search.split("=")[1];


    const [values, setValues] = useState({
       
        password: '',
        c_password: ''
    });

    const [errors, setErrors] = useState({});

    function handleChange(e) {
        setValues({ ...values, [e.target.name]: e.target.value })
    }
    function handleReset(e) {
        e.preventDefault();
        setErrors(validation(values));
    }

    useEffect(() => {
        if (token !== "") {
            http.get(`/reset-details/${token}/getDetails`)
                .then((res) => {
                    if (res.data.success === false) {
                        toast.error(res.data.message, {
                            position: "top-center"
                        });

                    }
                    else {
                        if (res.data.data.length > 0) {
                            setValues({
                                email: res.data.data[0].email
                            })
                        } else {
                            toast.error("Reset Link not vaild!", {
                                position: "top-center"
                            });
                        }

                    }


                })
                .catch(err => {
                    toast.error(err.response.data.message, {
                        position: "top-center"
                    });
                })

        }

        if (token !== "" && values.password !== "") {
            http.post('/reset-password', { token: token, password: values.password, password_confirmation: values.c_password }).then((res) => {

                if (res.data.success === false) {
                    toast.error(res.data.message, {
                        position: "top-center"
                    });

                    navigate(`/resetpassword?token=${token}`, { replace: false });
                }
                else {
                    setValues({
                        password: '',
                        c_password: ''
                    })
                    toast.success(res.data.message, {
                        position: "top-center"
                    });
                    navigate(`/resetpassword?token=${token}`, { replace: false });
                }

            });
        }
    }, [errors, token]);
    return (
        <Fragment>
            <div className="d-lg-flex half">
                <SideBar />
                <div className="contents">
                    <div className="container" data-aos="fade-right">

                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-11 LogInFrom mt-5" >
                                {/* <div className="row mt-4 mb-4">
                                    <div className="col-md-6 col-sm-12">
                                        <p> <Link to="/"> <i className="fa fa-arrow-left"></i>Back to the homepage</Link></p>
                                    </div>
                                    <div className="col-md-6 col-sm-12 ">
                                        <div className="col-md-12 top text-center">
                                            <span className="helper-text">Don't have an account? <a href="./register.html">Register from here <i className="fa fa-arrow-right" ></i></a></span>
                                        </div>
                                    </div>
                                </div> */}
                                <h1>Reset Password</h1>
                                <p className="mb-4">You can now reset your password</p>
                                {errorMessage && (
                                    <p style={{ color: "red" }}> {errorMessage} </p>
                                )}

                                <form onSubmit={handleReset}>
                                    <div className="row">

                                        <div className="col-md-12 floating-label">
                                            <label>Password:</label>
                                            <input type="password" name="password" value={values.password} onChange={handleChange}></input>
                                            {errors.password && <p style={{ color: "red" }}>{errors.password}</p>}
                                        </div>
                                        <div className="col-md-12 floating-label">
                                            <label>Confirm Password:</label>
                                            <input type="password" name="c_password" value={values.c_password} onChange={handleChange}></input>
                                            {errors.c_password && <p style={{ color: "red" }}>{errors.c_password}</p>}
                                        </div>

                                        <div className="col-md-12 mb-3 LogButtons text-center">
                                            <button className="btn login" type="submit">Reset</button>
                                            <Link className="btn Register  mr-3" to="/">Log In</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </Fragment>
    )
}

export default Resetpassword;