import React, { Fragment } from "react";
import { Modal, Button } from 'react-bootstrap';
const UseTemplates = (props) => {
    const { errors, selectTemplate, handleBackStep, handleShow, handleClose, show, handleSelectTemplate, handleSubmitTemplate, handleSelectButton, modal_id } = props;
    return (
        <Fragment>
            <div className="col-md-3">
                {/* Tab navs */}
                <div className="nav flex-column nav-tabs text-center New-auditTabs" id="v-tabs-tab">
                    <span className="nav-link">Create new audit</span>
                    <span className="nav-link">Keynote speech</span>
                    <span className="nav-link active">Use forms/templates</span>
                    <span className="nav-link">Generate link</span>
                    <span className="nav-link">Review &amp; Save</span>
                </div>
                {/* Tab navs */}
            </div>
            <div className="col-md-9">
                <div className="Audit-form-div">
                    {/* Tab content */}
                    <div className="tab-content">
                        {/* Start Explore Templates*/}
                        <div className="tab-pane active" id="v-tabs-templates" role="tabpanel">
                            <div className="audit-form">
                                <div className="audit-header">
                                    <h5 className="audit-title">Explore Templates</h5>
                                </div>
                                <div className="audit-form-content">
                                    <form onSubmit={handleSubmitTemplate}>
                                        {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
                                        <div className="row">
                                            {/* <div className="col-lg-4 col-md-4 col-sm-6 on-device">
                                                <div className="templates-box ">
                                                    <input type="radio" name="select_Template" checked={selectTemplate.selectedName === 'Template 1' || selectTemplate.template_name === 'Template 1'} value="Template 1" id="1" onChange={handleSelectTemplate} />
                                                    <label htmlFor="1">
                                                        <img alt='Template' src="/assets/images/temp1.png" className="img-fluid" />
                                                        <div className="templates-text text-center">
                                                            <h4>Template 1</h4>
                                                            <div className="select-view-action">
                                                                <input type="button" className="select-btn" name="1" id="Template 1" defaultValue="Select" onClick={handleSelectButton} />
                                                                <input type="button" className="view-btn" defaultValue="View" id="viewTemplate-1" onClick={handleShow} />
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6  on-device">
                                                <div className="templates-box">
                                                    <input type="radio" name="select_Template" checked={selectTemplate.selectedName === 'Template 2' || selectTemplate.template_name === 'Template 2'} value="Template 2" id="2" onChange={handleSelectTemplate} />
                                                    <label htmlFor="2">
                                                        <img alt='Template' src="/assets/images/temp2.png" className="img-fluid" />

                                                        <div className="templates-text text-center">
                                                            <h4>Template 2</h4>
                                                            <div className="select-view-action">
                                                                <input type="button" className="select-btn" name="2" id="Template 2" defaultValue="Select" onClick={handleSelectButton} />
                                                                <input type="button" className="view-btn" defaultValue="View" id="viewTemplate-2" onClick={handleShow} />
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> */}
                                            <div className="col-lg-4 col-md-4 col-sm-6  on-device">
                                                <div className="templates-box ">
                                                    <input type="radio" name="select_Template" checked={selectTemplate.selectedName === 'Template 3' || selectTemplate.template_name === 'Template 3'} value="Template 3" id="3" onChange={handleSelectTemplate} />
                                                    <label htmlFor="3">
                                                        <img alt='Template' src="/assets/images/temp3.png" className="img-fluid" />

                                                        <div className="templates-text text-center">
                                                            <h4>Template 3</h4>
                                                            <div className="select-view-action">
                                                                <input type="button" className="select-btn" name="3" id="Template 3" defaultValue="Select" onClick={handleSelectButton} />
                                                                <input type="button" className="view-btn" defaultValue="View" id="viewTemplate-3" onClick={handleShow} />
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-4 col-md-4 col-sm-6  on-device">
                                                <div className="templates-box ">
                                                    <input type="radio" name="select_Template" checked={selectTemplate.selectedName || selectTemplate.template_name === 'Template 4'} value="Template 4" id="4" onChange={handleSelectTemplate} />
                                                    <label htmlFor="4">
                                                        <img alt='Template' src="/assets/images/temp4.png" className="img-fluid" />

                                                        <div className="templates-text text-center">
                                                            <h4>Template 4</h4>
                                                            <div className="select-view-action">
                                                                <input type="button" className="select-btn" name="4" id="Template 4" defaultValue="Select" onClick={handleSelectButton} />
                                                                <input type="button" className="view-btn" defaultValue="View" id="viewTemplate-4" onClick={handleShow} />
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6  on-device">
                                                <div className="templates-box ">
                                                    <input type="radio" name="select_Template" checked={selectTemplate.selectedName || selectTemplate.template_name === 'Template 5'} value="Template 5" onChange={handleSelectTemplate} id="5" />
                                                    <label htmlFor="5">
                                                        <img alt='Template' src="/assets/images/temp5.png" className="img-fluid" />

                                                        <div className="templates-text text-center">
                                                            <h4>Template 5</h4>
                                                            <div className="select-view-action">
                                                                <input type="button" className="select-btn" name="5" id="Template 5" defaultValue="Select" onClick={handleSelectButton} />
                                                                <input type="button" className="view-btn" defaultValue="View" id="viewTemplate-5" onClick={handleShow} />
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* start footer buttons*/}
                                        <div className="col-md-12 px-0">
                                            <div className="text-right d-block audit-form-footer">
                                                <button type="button" className="mr-2 text-Bck btn" onClick={handleBackStep}>Back</button>
                                                <button type="submit" className="mr-2 text-Next btn">Save &amp; Next</button>
                                                {/* <button type="button" className="mr-2 text-Next btn" disabled>Save as Draft</button>
                                                <button type="button" className="text-Share btn" disabled>Share Audit</button> */}
                                            </div>
                                        </div>
                                        {/* End footer buttons*/}
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* End Explore Templates*/}
                    </div>
                    {/* Tab content */}
                </div>
            </div>
            {/* Modal */}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="templatesModal"
                size="lg"
                id={`templates-Modal${modal_id}`}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h4>Template - {modal_id}</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row clearfix">
                        <div className="col-12">
                            <h4>Team Action Management</h4>
                            <p>Delivers Successful Change &amp; Increase The Performance To All People-Based Organizations</p>
                            <h4>Keynote Speech</h4>
                            <p>{selectTemplate.audit_speech_detials}<a className="ml-5 text-underline" href={selectTemplate.audit_pdf.includes("public") ? `https://api.teamactionmanagement.com/storage${selectTemplate.audit_pdf.replace("public", "")}` : selectTemplate.audit_pdf} target="_blank" rel="noreferrer">View Keynote Speech</a></p>
                            <div className="breadcrumb vt-a">
                                <a href="#">
                                    Template {modal_id}: Your Views/Opinions</a>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="shortTitle">Please give a short title </label>
                                <span><input id="shortTitle" type="text" className="form-control" placeholder="Title" /></span>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="same_field_box red-border">
                                <label>Describe which box your title refers to &amp; tick </label>
                                <div className="row mt-2">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <h6>Present</h6>
                                        <div className="custom-radio">
                                            <input type="radio" name="diseaseans" id="Strength" />
                                            <label htmlFor="Strength">Strength</label>
                                        </div>
                                        <div className="custom-radio">
                                            <input type="radio" name="diseaseans" id="Fault" />
                                            <label htmlFor="Fault">Fault</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <h6>Present</h6>
                                        <div className="custom-radio">
                                            <input type="radio" name="diseaseans" id="Opportunity" />
                                            <label htmlFor="Opportunity">Opportunity</label>
                                        </div>
                                        <div className="custom-radio">
                                            <input type="radio" name="diseaseans" id="Threat" />
                                            <label htmlFor="Threat">Threat</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <label htmlFor="opinion">Statement of your view or opinion - 1 per form please -</label>
                            <div className="text_field_box spacing-top">
                                <textarea id="opinion" placeholder="Enter text" />
                            </div>
                        </div>
                        <div className="col-12">
                            <label htmlFor="example">Do you want to give an example?</label>
                            <div className="text_field_box spacing-top">
                                <textarea id="example" placeholder="Enter text" />
                            </div>
                        </div>
                        <div className="col-12">
                            <label htmlFor="anythingDone">What could be done -</label>
                            <div className="text_field_box spacing-top">
                                <textarea id="anythingDone" placeholder="Enter text" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Submit</Button>
                </Modal.Footer>
                <div className="footer-bottom">
                    <p className="footer-text">Unleashing the power</p>
                    <p> TAM Uk © 2023, Team Action Management </p>
                </div>
            </Modal>
        </Fragment>
    )
}
export default UseTemplates;