
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export default function Footer() {

    return (
        <Fragment>

            <div className="col-md-12 bottom text-right mt-5">
                <ul className="nav-footer-link-list">
                    <li><Link className="external-link" to="#">Help</Link></li>
                    <li><Link className="external-link" to="#">Terms of Use</Link></li>
                    <li><Link className="external-link" to="#">Cookies Policy</Link></li>
                    <li><Link className="external-link" to="#">Privacy Notice</Link></li>
                    <li><Link className="external-link" to="#">Cookie Preferences</Link></li>
                </ul>

            </div>


        </Fragment>
    )
}
