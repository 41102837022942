export const Validation = (values) => {
    let errors = {}

    if (!values.title) {
        errors.title = "Please enter Title";
    }
    if (!values.refers_to) {
        errors.refers_to = "Please select any one option.";
    }
    if (!values.statement_view) {
        errors.statement_view = "Please enter your view or opinion.";
    }
    // if (!values.example) {
    //     errors.example = "Please give an example.";
    // }
    // if (!values.done) {
    //     errors.done = "Please mention what could be done.";
    // }
    // if (!values.termsAndConditions) {
    //     errors.terms = "Please read and select Terms & Conditions.";
    // }
    return errors;
}

export const authValidation = (auth, token) => {
    let authErrors = {}

    // if (!auth.name) {
    //     authErrors.name = "Please enter your name";
    // }
    // if (!auth.email) {
    //     authErrors.email = "Email is required!";
    // } 
    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(auth.email)) {
    //     authErrors.email = 'Invalid email address'
    // }
    if (auth.token !== token) {
        authErrors.token = "Please enter valid token.";
    }
    return authErrors;
}

export const profileVaidation = (values) => {
    let errors = {}

    if (!values.name) {
        errors.name = "Please enter your name";
    }
    if (!values.password) {
        errors.password = "Password is required!";
    } else if (values.password.length < 6) {
        errors.password = "Password must be 6 character!";
    }
    if (!values.password_confirmation) {
        errors.password_confirmation = "Confirm Password is required!";
    } else if (values.password_confirmation.length < 6) {
        errors.c_password = "Confirm Password must be 6 character!";
    }

    if (values.password_confirmation !== values.password) {
        errors.password_confirmation = "Password not match";
    }

    return errors;
}